import React, { useState } from "react";
// import "./PromotionDetails.scss";
import "./PromotionRacesAndLeaderboards.scss";
import { promotionCarousel1 } from "../../components/Images";
import { Outlet, useParams } from "react-router-dom";
import PromotionsBanner from "../promotions/PromotionsBanner";
import { arrowLeft, arrowRight } from "../../components/Images";

import Carousel, { consts } from "react-elastic-carousel";
import { useQuery } from "react-query";
import { getLeaderboardDetails, getRaceDetails } from "../../queryFunctions";
import LoadingElement from "../../components/LoadingElement";
import { useEffect } from "react";
const arrowImages = ({ type, onClick, isEdge }) => {
  const img = type === consts.PREV ? arrowLeft : arrowRight;
  return (
    <img
      src={img}
      style={{ width: "9px", height: "14px", margin: "auto" }}
      onClick={onClick}
      disabled={isEdge}
    />
  );
};
export default function RaceOutlet() {
  const { id, raceId, leaderId, tourId, name } = useParams();
  const replacedName = name.split("-").join(" ");
  let promoid;
  console.log("******* Race details Page stars here ********");
  const [isActiveState, setActiveState] = useState("race");
  const [raceDate, setRaceDate] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [raceDate]);

  const raceAPI = useQuery(
    ["racedetails", id, raceId],
    () => getRaceDetails(id, raceId),
    {
      onSuccess: (data) => {
        //console.log("race-details", data);
        //console.log("success message");
        const performanceStart = Date.now();
        if (data.races === undefined || data.races.length == 0) {
          //   setCarouselArrayData("NoData");
          setRaceDate("NaData");
          return;
        }
        const newArraydata = convertObjectToArray(data);
        console.log("new Array Data response", newArraydata);
        // const reversed = new Map([...newArraydata.value].reverse());
        const reversed = newArraydata.value.reverse();
        console.log("reversed", reversed);
        newArraydata.value = reversed;
        data.carouselArrayData = newArraydata;
        setRaceDate(
          (prevDate) =>
            prevDate ||
            data.carouselArrayData.uniqueDate[
              data.carouselArrayData?.uniqueDate?.length - 1
            ] ||
            ""
        );
        const performanceDuration = Date.now() - performanceStart;
        console.log(
          "Performance Duration",
          performanceDuration,
          performanceStart
        );
      },

      refetchInterval: 10000,
    }
  );

  const breakPointsForRaces = [
    { width: 1, itemsToShow: 3 },
    { width: 568, itemsToShow: 5 },
    { width: 992, itemsToShow: 8 },
  ];

  const convertedTime = (val) => {
    let timeValue;

    if (val[0] == 0) {
      //console.log("time for 00", val[0]);
      timeValue = `12:${val[1]} AM`;
    }
    if (val[0] > 0 && val[0] < 12) {
      timeValue = `${val[0]}:${val[1]} AM`;
    }
    if (val[0] == 12) {
      timeValue = `12:${val[1]} PM`;
    }
    if (val[0] > 12) {
      timeValue = `${val[0] - 12}:${val[1]} PM`;
    }
    return timeValue;
  };

  const convertObjectToArray = (data) => {
    //console.log("Object is being converted to array in thedata");
    //console.log("data in convert object to array", data);
    let newArray = [];
    let newUniqueDateArray = [];
    Object.entries(data.races).forEach(([key, value], index) => {
      const fromDate = value.from_date.split(" ")[0].split("-");
      // //console.log("from date", fromDate);

      const newValue = value;
      const newDateValue = `${fromDate[2]}/${fromDate[1]}`;
      //console.log(newUniqueDateArray.includes(newDateValue));
      const currentDate = new Date().getDate();
      console.log("currend date of today", currentDate);
      newUniqueDateArray.includes(newDateValue) === false &&
        newUniqueDateArray.push(newDateValue);
      newValue.fromDate = newDateValue;
      // calculate time for races
      const fromTime = value.from_date.split(" ")[1].split(":");
      const toTime = value.to_date.split(" ")[1].split(":");

      newValue.fromTime = convertedTime(fromTime);
      newValue.toTime = convertedTime(toTime);
      newArray.push(newValue);
    });

    const newReturnObject = { value: newArray, uniqueDate: newUniqueDateArray };

    //console.log(newReturnObject);
    return newReturnObject;
  };
  const setRaceDateHandler = (val) => {
    setRaceDate(val);
  };

  // carouselArrayData &&
  //   setRaceDateHandler(
  //     carouselArrayData.uniqueDate[carouselArrayData.uniqueDate.length - 1]
  //   );

  if (raceAPI.isLoading) {
    return <LoadingElement />;
  }
  if (raceAPI.isError) {
    return <div>OOPS!! Something went wrong</div>;
  }
  //   if (carouselArrayData.value.length > 0) {
  return (
    <>
      <div className="row margin-top-leaderboard-section container-fluid">
        <div className="d-flex flex-column-reverse col-10 col-lg-11  mx-auto justify-content-between">
          <div className="flex-basis-45 flex flex-column justify-content-center align-items-center px-4 my-auto">
            <p className="text-center leaderboard-name mb-0 mt-4 mt-sm-0">
              {raceAPI.data.race_detail[0].racetype_name
                .toLowerCase()
                .replace("race", "")}
            </p>
            <p className="text-center leadearboard-title">race</p>
          </div>
        </div>
      </div>
      {/* Table for Races */}
      <div className="container-fluid row mx-auto table-margin-top">
        <div className="col-10 col-lg-11 mx-auto">
          {console.log("Race api before filter", raceAPI.data)}
          {console.log("carousel array data", raceAPI.data.carouselArrayData)}
          {raceAPI.data.carouselArrayData == undefined ? (
            <div>
              <p className="text-center">There are no races to display</p>
            </div>
          ) : (
            raceAPI.data.carouselArrayData.value
              .filter((filtered) => filtered.fromDate === raceDate)
              .map((item, index) => (
                <table
                  style={{ width: "100%", marginBottom: "40px" }}
                  key={index}
                >
                  <thead>
                    <tr>
                      <td aria-colspan={3} colSpan={3}>
                        <div className="d-flex justify-content-between">
                          <div className=" d-none d-lg-block flex-grow-1" />
                          <div className="date-button-with-background  flex-grow-1">
                            {item.fromDate} - {item.title}
                          </div>
                          <div className="flex-basis-45-lg-33 d-flex align-items-center justify-content-center font-bold">
                            {item.fromTime} - {item.toTime}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th style={{ padding: "10px" }}>RANK</th>
                      <th style={{ padding: "10px" }}>NICKNAME</th>
                      <th style={{ padding: "10px" }}>POINTS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.data.map((playerData, index) => (
                      <tr key={index}>
                        <td>{playerData.rank}</td>
                        <td>{playerData.player_name}</td>
                        <td>{playerData.points}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))
          )}
        </div>
      </div>
      {raceAPI.data.carouselArrayData == undefined ? null : raceAPI.data
          .carouselArrayData.uniqueDate.length < 7 ? (
        <div className="container-fluid row mt-5">
          <div className="col-11 col-sm-10 mx-auto ">
            <div className="d-flex flex-wrap justify-content-center mx-auto">
              {raceAPI.data.carouselArrayData.uniqueDate.map((item) => (
                <div
                  className={`carousel-button margin-right-2rem mt-3 ${
                    raceDate === item ? "selected-carousel-button" : ""
                  }`}
                  onClick={() => setRaceDate(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid row mt-5">
          <div className="col-11 col-sm-10 mx-auto">
            <div>
              <Carousel
                renderArrow={arrowImages}
                breakPoints={breakPointsForRaces}
                pagination={false}
                initialActiveIndex={
                  raceAPI.data.carouselArrayData.uniqueDate.length - 1
                }
                transitionMs={500}
              >
                {raceAPI.data.carouselArrayData.uniqueDate.map((item) => (
                  <div
                    className={`carousel-button ${
                      raceDate === item ? "selected-carousel-button" : ""
                    }`}
                    onClick={() => setRaceDate(item)}
                  >
                    {item}
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      )}
    </>
  );
  //   }
  //   return <div>There are no races to display</div>;
}
