import React, { useState } from "react";
import "./Promotions.scss";

import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  downArrow,
  expired,
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  promotion,
  promotionsBanner,
} from "../components/Images";
import { getPromotions } from "../queryFunctions";
import PromotionsBanner from "./promotions/PromotionsBanner";
import LoadingElement from "../components/LoadingElement";

const PromotionSelector = ({
  value,
  setPromotionState,
  promotionState,
  title,
  promotionTypeValue,
}) => {
  return (
    <>
      {value.find(({ promotion_type }) => promotion_type === "Cash_Game") ? (
        <div
          className={
            (promotionState === "Cash_Game"
              ? "promotion-button selected-promotion-button"
              : "promotion-button") + " button-margin"
          }
          onClick={() => setPromotionState("Cash_Game")}
        >
          cash table <br className="d-lg-none" />
          Promotion
        </div>
      ) : (
        setPromotionState("Tournament")
      )}
      {value.find(({ promotion_type }) => promotion_type === "Tournament") && (
        <div
          className={
            promotionState === "Tournament"
              ? "promotion-button button-margin selected-promotion-button"
              : "promotion-button button-margin"
          }
          onClick={() => setPromotionState("Tournament")}
        >
          tournament <br className="d-lg-none" />
          promotion
        </div>
      )}
      {value.find(({ promotion_type }) => promotion_type === "Cash_Tour") && (
        <div
          className={
            promotionState === "Cash_Tour"
              ? "promotion-button selected-promotion-button"
              : "promotion-button"
          }
          onClick={() => setPromotionState("Cash_Tour")}
        >
          Tournament Cash <br className="d-lg-none" />
          promotion
        </div>
      )}
    </>
  );
};

const PreviousPromotions = ({ data, promotionState }) => {
  const excludePromotionId = ["45", "46", "47", "48"];
  return (
    <>
      {data.data
        .filter(
          (item) =>
            item.promotion_status === "Inactive" &&
            item.promotion_type === promotionState
        )
        .map((prom) => (
          <>
            {excludePromotionId.includes(prom.promotion_id) ? null : (
              <div className="col-11 col-sm-10 col-md-9  promotion-item  mx-auto">
                <img src={prom.promotion_banner} className="promotion-image" />

                <div class="d-flex gap-3 justify-content-between p-4 px-md-5 align-items-center">
                  <p className="promotion-name">{prom.promotion_name}</p>
                  <Link
                    to={`/promotion/${prom.promotion_id}`}
                    className="promotion-link"
                  >
                    Read More
                  </Link>
                  <a
                    className="promotion-link"
                    href="https://web.pokersaint.com/"
                    target="_blank"
                  >
                    Play Now
                  </a>
                </div>
              </div>
            )}
          </>
        ))}
    </>
  );
};

export default function Promotions() {
  const { data, isLoading, isError, error } = useQuery(
    "promotions",
    getPromotions
  );
  const [promotionState, setPromotionState] = useState("Cash_Game");
  const [previousPromotionView, setPreviousPromotionView] = useState(false);

  if (isLoading) {
    return <LoadingElement />;
  }
  if (isError) {
    return <div>OOPS!! Something went wrong</div>;
  }
  return (
    <>
      {/* Promotion banner Start */}
      <PromotionsBanner title="Promotions" />

      {/* promotion Selection */}

      <div className="promotion-type-selector">
        <PromotionSelector
          value={data && data.data}
          setPromotionState={setPromotionState}
          promotionState={promotionState}
        />
      </div>

      {/* Promotion Items */}

      <div className="row  promotion-items-container container-fluid gap-lg-5">
        {data.data.filter(
          (item) =>
            item.promotion_status === "Active" &&
            item.promotion_type === promotionState
        ).length === 0 ? (
          <div className="container-fluid d-flex align-items-center justify-content-center mt-5">
            <p className="font-size">
              There is no promotions at this point in time.
            </p>
          </div>
        ) : (
          data.data
            .filter(
              (item) =>
                item.promotion_status === "Active" &&
                item.promotion_type === promotionState
            )
            .map((prom) => (
              <div className="col-11 col-sm-10 col-md-9 col-lg-5 promotion-item ">
                <img src={prom.promotion_banner} className="promotion-image" />

                <div class="d-flex gap-3 justify-content-between p-4 px-md-5 align-items-center">
                  <p className="promotion-name">{prom.promotion_name}</p>
                  <Link
                    to={`/promotion/${prom.promotion_id}`}
                    className="promotion-link"
                  >
                    Read More
                  </Link>
                  <a
                    className="promotion-link"
                    href="https://web.pokersaint.com/"
                    target="_blank"
                  >
                    Play Now
                  </a>
                </div>
              </div>
            ))
        )}
      </div>

      {/* Previous Promotions */}
      <div className=" row container-fluid mx-auto">
        <div
          className={`previous-promotions-container col-11 col-sm-10 col-md-9 col-lg-7  mx-auto  ${
            previousPromotionView
              ? "selected-previous-promotions"
              : "previous-promotions"
          }`}
        >
          <div
            className={`d-flex justify-content-between align-items-center px-4 py-2 py-lg-3`}
            onClick={() => setPreviousPromotionView(!previousPromotionView)}
          >
            <p className="previous-promotions-title">Previous Promotions</p>
            <div className="previous-promotions-image" />
          </div>
          {previousPromotionView && (
            <div className="d-none d-lg-flex mx-auto row">
              <PreviousPromotions data={data} promotionState={promotionState} />
            </div>
          )}
        </div>
        {previousPromotionView && (
          <div className="row d-lg-none ">
            <PreviousPromotions data={data} promotionState={promotionState} />
          </div>
        )}
      </div>
    </>
  );
}
