import React, { useState } from "react";
// import "./PromotionDetails.scss";
import "./PromotionRacesAndLeaderboards.scss";
import { promotionCarousel1 } from "../../components/Images";
import { Outlet, useParams } from "react-router-dom";
import PromotionsBanner from "../promotions/PromotionsBanner";
import { arrowLeft, arrowRight } from "../../components/Images";

import Carousel, { consts } from "react-elastic-carousel";
import { useQuery } from "react-query";
import { getSocialDetails } from "../../queryFunctions";
import LoadingElement from "../../components/LoadingElement";
import { useEffect } from "react";
const arrowImages = ({ type, onClick, isEdge }) => {
  const img = type === consts.PREV ? arrowLeft : arrowRight;
  return (
    <img
      src={img}
      style={{ width: "9px", height: "14px", margin: "auto" }}
      onClick={onClick}
      disabled={isEdge}
    />
  );
};
export default function SocialLeaderboardOutlet() {
  const { id, raceId, leaderId, stakeId, tourId, name } = useParams();
  const replacedName = name.split("-").join(" ");
  let promoid;
  console.log("******* Race details Page stars here ********");
  const [isActiveState, setActiveState] = useState("race");
  const [raceDate, setRaceDate] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [raceDate]);

  const raceAPI = useQuery(
    ["socialdetails", id, stakeId],
    () => getSocialDetails(id, stakeId),
    {
      onSuccess: (data) => {
        console.log("race-details", data);
        //console.log("success message");
        setRaceDate(data);
      },

      refetchInterval: 10000,
    }
  );
console.log(raceAPI);
  if (raceAPI.isLoading) {
    return <LoadingElement />;
  }
  if (raceAPI.isError) {
    return <div>OOPS!! Something went wrong</div>;
  }
  //   if (carouselArrayData.value.length > 0) {
  return (
    <>
      <div className="row margin-top-leaderboard-section container-fluid">
        <div className="d-flex flex-column-reverse col-10 col-lg-11  mx-auto justify-content-between">
          <div className="flex-basis-45 flex flex-column justify-content-center align-items-center px-4 my-auto">
            <p className="text-center leaderboard-name mb-0 mt-4 mt-sm-0">
              {raceAPI.data.data.stakes[0].stakes_name
                .toLowerCase()
                .replace("race", "")}
            </p>
            <p className="text-center leadearboard-title">Social Leaderboard</p>
          </div>
        </div>
      </div>
      {/* Table for Races */}
      <div className="container-fluid row mx-auto table-margin-top">
        <div className="col-10 col-lg-11 mx-auto">
            {
                raceAPI.data.data.leaderboard.length === 0 ? (
                    <p className="text-center">No Data</p>
                ) : (
            
            <table className="table" style={{ width: "100%", marginBottom: "40px" }}>
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Name</th>
                        <th>Points</th>
                    </tr>
                </thead>
                <tbody>
                {
                raceAPI.data.data && raceAPI.data.data.leaderboard.map((item, index) => (
                    <>
                    <tr key={index}>
                        <td>{item.rank}</td>
                        <td>{item.name}</td>
                        <td>{item.points}</td>
                    </tr>
                    </>
                ))
                }
                </tbody>
            </table>
            )}
        </div>
      </div>
    </>
  );
  //   }
  //   return <div>There are no races to display</div>;
}
