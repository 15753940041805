import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import Home from "../pages/Home";
import Achievements from "../pages/Achievements";
import Deposits from "../pages/Deposits";
import Faq from "../pages/Faq";
import Tds from "../pages/Tds";
import PromotionDetails from "../pages/PromotionDetails";
import Promotions from "../pages/Promotions";
import Blogs from "../pages/Blogs";
import BlogDetails from "../pages/BlogDetails";

import LiveTournament from "../pages/LiveTournament";
import PromotionRacesAndLeaderboards from "../pages/promotion_details/PromotionRacesAndLeaderboards";
import RaceOutlet from "../pages/promotion_details/RaceOutlet";
import LeaderboardOutlet from "../pages/promotion_details/LeaderboardOutlet";
import TermsOfUse from "../pages/TermsOfUse";
import DisconnectionPolicy from "../pages/DisconnectionPolicy";
import Leagality from "../pages/Leagality";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ResponsibleGaming from "../pages/ResponsibleGaming";
import PotLimitOmaha from "../pages/PotLimitOmaha";
import NoLimitTexasHoldem from "../pages/NoLimitTexasHoldem";
import Rake from "../pages/Rake";
import OpenFaceChinese from "../pages/OpenFaceChinese";
import SocialLeaderboardOutlet from "../pages/promotion_details/SocialLeaderboardOutlet";

function NoMatch() {
  return (
    <div className="d-flex flex-column align-items-center">
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/" className="">
          Go to the home page
        </Link>
      </p>
    </div>
  );
}

export default function RoutesFunction() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/achievements" element={<Achievements />} />
        <Route path="/offers/deposit" element={<Deposits />} />
        <Route path="/offers/bonus" element={<Deposits />} />
        <Route path="/offers/instant" element={<Deposits />} />
        <Route path="/rake" element={<Rake />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/tds-policy" element={<Tds />} />
        <Route path="/promotions" element={<Promotions />} />
        <Route path="/tournaments" element={<LiveTournament />} />
        <Route path="/promotion/:id" element={<PromotionDetails />}>
          {/* <Route index element={<Description />} /> */}
        </Route>
        <Route
          path="/promotion/:id/:name/:raceId/:leaderId/:stakeId"
          element={<PromotionRacesAndLeaderboards />}
        >
          <Route path="race" element={<RaceOutlet />} />
          <Route path="leaderboard" element={<LeaderboardOutlet />} />
          <Route path="social" element={<SocialLeaderboardOutlet />} />
        </Route>
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:id" element={<BlogDetails />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/disconnection-policy" element={<DisconnectionPolicy />} />
        <Route path="/leagality" element={<Leagality />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/responsible-gaming" element={<ResponsibleGaming />} />
        <Route
          path="/no-limit-texas-holdem-poker"
          element={<NoLimitTexasHoldem />}
        />
        <Route path="/open-face-chinese-poker" element={<OpenFaceChinese/>}></Route>
        <Route path="/pot-limit-omaha" element={<PotLimitOmaha />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
  );
}
