import React from "react";
import { useQuery } from "react-query";
import BannerCarousel from "../components/BannerCarousel";
import { faq } from "../components/Images";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  a1,
  a2,
  a3,
  a4,
  a1Alt,
  a2Alt,
  a3Alt,
  a4Alt,
  app,
  bannerMobile,
  c1,
  c2,
  c4,
  c3,
  c5,
  c6,
  cm11x,
  cm12x,
  cm13x,
  cm21x,
  cm22x,
  cm23x,
  cm31x,
  cm32x,
  cm33x,
  cm41x,
  cm42x,
  cm43x,
  i1,
  i2,
  i3,
  i4,
  m1,
  m2,
  m3,
  m4,
  m5,
  slider1,
  slider2,
  slider3,
} from "../components/Images";
import LoadingElement from "../components/LoadingElement";
import PromotionsCarousel from "../components/PromotionsCarousel";
import Testimonials from "../components/Testimonials";
import { getBanners, getPromotions, getTestimonial } from "../queryFunctions";
import Promotions from "./Promotions";

export default function Home() {
  // const [card, setCard] = useState(null);
  // const onCardChange = (event) => {
  //   console.log("Card", event);
  // };

  var settings = {
    dots: true,
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
  };

  // const {
  //   data: testimonialdata,
  //   isLoading: testimonialisLoading,
  //   isError: testimonialisError,
  //   error: testimonialerror,
  // } = useQuery("testimonials", getTestimonial);
  // const {
  //   data: promotiondata,
  //   isLoading: promotionisLoading,
  //   isError: promotionisError,
  //   error: promotionerror,
  // } = useQuery("promotions", getPromotions);
  // const {
  //   data: bannerdata,
  //   isLoading: bannerisLoading,
  //   isError: bannerisError,
  //   error: bannererror,
  // } = useQuery("banner", getBanners);
  const FaqQuestion = ({ title, children }) => {
    const [openQuestion, setOpenQuestion] = useState(false);
    return (
      <div className="mb-4 ">
        <div className="d-flex align-items-center mb-2">
          <h4 className="font-weight-bold font-weight-bold my-auto">{title}</h4>
          <div
            className="faq-button "
            onClick={() => setOpenQuestion(!openQuestion)}
          >
            {openQuestion ? (
              <FaChevronDown size="1.3rem" color="white" />
            ) : (
              <FaChevronRight size="1.3rem" color="white" />
            )}
          </div>
        </div>
        {openQuestion ? children : null}
        <div style={{ borderBottom: "1px solid" }} className="mt-4" />
      </div>
    );
  };

  const testimonialAPI = useQuery("testimonials", getTestimonial);
  const promotionAPI = useQuery("promotions", getPromotions);
  const bannerAPI = useQuery("banner", getBanners);
  if (
    testimonialAPI.isLoading ||
    promotionAPI.isLoading ||
    bannerAPI.isLoading
  ) {
    <LoadingElement />;
  }
  if (testimonialAPI.isError || promotionAPI.isError || bannerAPI.isError) {
    <div>Oops!! Something went wrong</div>;
  }

  return (
    <>
      {console.log("testimonialAPI Home Page")}

      {bannerAPI.data && <BannerCarousel data={bannerAPI.data.data} />}

      {promotionAPI.data && (
        <PromotionsCarousel data={promotionAPI.data.data} />
      )}

      <div className="">
        <div className="container px-4">
          <div className="main-head">
            <h1 className="">Play India's Best Online Poker Game</h1>
          </div>
          <p className="text-center ">
            With an 'Instant Cashout' feature for the first time in India,
            PokerSaint - one of India’s leading online Poker platforms disrupted
            the gaming industry in India! PokerSaint launched by Pacific Gaming
            was recognised by The Economic Times as <b><a href="https://www.pokersaint.com/">" THE MOST PROMISING ONLINE
            GAMING BRAND IN INDIA "</a></b> for 2018. PokerSaint ensures fair play and
            the best Poker online user experience. PokerSaint promises the
            ultimate online Poker gaming experience and assured real money
            rewards! PokerSaint is deservedly ranked among the <b><a href="https://www.pokersaint.com/">best Poker
            websites in India.</a></b>
          </p>
          <div className="con-img d-none d-lg-block">
            <img alt="" src={c1} className="img-response frst-img" />
            <img alt="" src={c2} className="img-response" />
            <img alt="" src={c3} className="img-response" />
            <img alt="" src={c4} className="img-response d-none d-lg-inline" />
            <img alt="" src={c5} className="img-response d-none d-lg-inline" />
            <img alt="" src={c6} className="img-response last-img" />
          </div>
          <div className="con-img d-lg-none">
            <img
              alt=""
              src={c1}
              srcSet={`${cm11x} 300w,${cm12x} 768w,${cm13x} 992w`}
              className="img-response frst-img"
            />
            <img
              alt=""
              src={c2}
              srcSet={`${cm21x} 300w,${cm22x} 768w,${cm23x} 992w`}
              className="img-response"
            />
            <img
              alt=""
              src={c3}
              srcSet={`${cm31x} 300w,${cm32x} 768w,${cm33x} 992w`}
              className="img-response"
            />
            <img
              alt=""
              src={c5}
              srcSet={`${cm41x} 300w,${cm42x} 768w,${cm43x} 992w`}
              className="img-response last-img"
            />
          </div>
        </div>
      </div>

      <section className="service-section">
        <div className="container px-4">
          <div className="row services gy-5">
            <div className="col-6 col-lg-3 ">
              <div className="service-tab">
                <div className="service-image-width m-auto">
                  <img alt="" src={i1} className="img-response " />
                </div>
                <h4 className="">
                  Free <br />
                  Registration
                </h4>
                <p>Instant registration on poker </p>
              </div>
            </div>

            <div className="col-6 col-lg-3 ">
              <div className="service-tab">
                <div className="service-image-width m-auto">
                  <img alt="" src={i2} className="img-response " />
                </div>
                <h4 className="">
                  Play <br />
                  With Friends
                </h4>
                <p>Play with friends from accross the country </p>
              </div>
            </div>
            <div className="col-6 col-lg-3 ">
              <div className="service-tab ">
                <div className="service-image-width m-auto">
                  <img alt="" src={i3} className="img-response " />
                </div>
                <h4 className="">
                  Enjoy <br />
                  Free Offers
                </h4>
                <p>Best offers on poker games </p>
              </div>
            </div>

            <div className="col-6 col-lg-3 ">
              <div className="service-tab">
                <div className="service-image-width m-auto">
                  <img alt="" src={i4} className="img-response " />
                </div>
                <h4 className="">
                  Fastest <br />
                  Withdrawals{" "}
                </h4>
                <p>Cashout within 60 seconds </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-section ">
        <div className="">
          <div className="row">
            <div className="col-12">
              <div className="main-head mb-5">
                <h2>Testimonials</h2>
              </div>
            </div>
          </div>
          {testimonialAPI.data && <Testimonials data={testimonialAPI.data} />}
        </div>
      </section>

      <section className="service-section ">
        <div className="">
          <div className="row">
            <div className="col-12">
              <div className="main-head mb-5">
                <h2>PAYMENT METHODS</h2>
              </div>
            </div>
          </div>
          <div className=" payment ">
            <div className="container">
              <div className="justify-content-center row">
                <img
                  alt=""
                  src={m1}
                  className=" col-6 col-lg-2 service-section-img"
                />
                <img
                  alt=""
                  src={m2}
                  className=" col-6 col-lg-2 service-section-img"
                />
                <img
                  alt=""
                  src={m3}
                  className=" col-6 col-lg-2 service-section-img"
                />
                <img
                  alt=""
                  src={m4}
                  className=" col-6 col-lg-2 service-section-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="service-section mobile">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="main-head">
                <h2>PAYMENT METHODS</h2>
              </div>
            </div>
          </div>
          <div className="row payment">
            <div className="col-xs-6">
              <img alt="" src={m3} className="img-response" />
            </div>
            <div className="col-xs-6">
              <img alt="" src={m1} className="img-response" />
            </div>
            <div className="col-xs-6">
              <img alt="" src={m4} className="img-response" />
            </div>
            <div className="col-xs-6">
              <img alt="" src={m2} className="img-response" />
            </div>
          </div>
        </div>
      </section> */}

      <section className="app">
        <div className="container px-5">
          <div className="row">
            <div className="col-12">
              <div className="main-head head">
                <h2>Download the App</h2>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-lg-8">
              <img alt="" src={app} className="img-response" width="100%" />
            </div>

            <div className="col-lg-4">
              <div className="row app-small">
                <div className="col-3 col-lg-5">
                  <img
                    alt=""
                    src={a1}
                    onMouseOver={(e) => (e.target.src = a1Alt)}
                    onMouseOut={(e) => (e.target.src = a1)}
                    className="img-response"
                  />
                </div>
                <div className="col-3 col-lg-5 ">
                  <img
                    alt=""
                    src={a2}
                    onMouseOver={(e) => (e.target.src = a2Alt)}
                    onMouseOut={(e) => (e.target.src = a2)}
                    className="img-response"
                  />
                </div>
                <div className="col-3 col-lg-5 ">
                  <img
                    alt=""
                    src={a3}
                    onMouseOver={(e) => (e.target.src = a3Alt)}
                    onMouseOut={(e) => (e.target.src = a3)}
                    className="img-response"
                  />
                </div>
                <div className="col-3 col-lg-5 ">
                  <img
                    alt=""
                    src={a4}
                    onMouseOver={(e) => (e.target.src = a4Alt)}
                    onMouseOut={(e) => (e.target.src = a4)}
                    className="img-response"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/** FAQ Section **/}
      <section>
        <div className="deposit-content promotion-content">
          <div className="container px-5 mt-5">
            <div
              className="main-head d-none d-lg-block"
              style={{ borderBottom: "1px solid" }}
            >
              <h2>Poker Online Game FAQ'S</h2>
            </div>
            <div className="main-head d-block d-lg-none">
              <h2>Poker Online Game FAQ'S</h2>
            </div>
            <div className="row" style={{ paddingTop: "20px" }}>
              <div>
                <FaqQuestion title="How Do I Start Playing On Poker Saint?">
                  <div class="acc-content current">
                    <div class="content">
                      <p>
                        PokerSaint Poker Games can be played on several devices!
                        Choose what’s best for you and start playing NOW for
                        FREE!!
                      </p>
                      <p>
                        - Instant Play: Click on the "PLAY NOW" button on the top
                        of the page to start playing instantly!
                      </p>
                      <p>
                        - Android Application: On the Home page, scroll to the
                        "ANDROID DOWNLOAD" section and click on the button to
                        download the latest App.
                      </p>
                      <p>
                        - iOS Application: Visit the istore and search for
                        “PokerSaint” to download it directly from the istore!{" "}
                      </p>
                      <p>
                        - Windows and MAC desktop: On the Home page, scroll to the
                        "DESKTOP DOWNLOAD" section and click on the button to
                        download the latest desktop App.
                      </p>
                    </div>
                  </div>
                </FaqQuestion>

                <FaqQuestion title="What Are The Rules For Omaha?">
                  <div class="acc-content">
                    <div class="content">
                      <p>
                        Please view our page{" "}
                        <Link to="/pot-limit-omaha">Omaha</Link>
                      </p>
                    </div>
                  </div>
                </FaqQuestion>
                <FaqQuestion title="What Are The Rules Of Texas Hold’em?">
                  <div class="acc-content">
                    <div class="content">
                      <p>
                        Please view our page{" "}
                        <Link to="/no-limit-texas-holdem-poker">
                          Texas Hold’em
                        </Link>
                      </p>
                    </div>
                  </div>
                </FaqQuestion>
                <FaqQuestion title="What Are The Rules Of OFC?">
                <div class="acc-content">
                  <div class="content">
                    <p>
                      Please view our page{" "}
                      <Link to="/open-face-chinese-poker">
                        Open Face Chinese (OFC)
                      </Link>
                    </p>
                  </div>
                </div>
              </FaqQuestion>
                <FaqQuestion title="How Do I Make A Deposit?">
                  <div>
                    <p>
                      To deposit money in your PokerSaint account to play Poker. All you need to do is follow these simple steps:
                    </p>
                    <p>
                      - Click on the “CASHIER” option in the app or web app.
                    </p>
                    <p>
                      -	Click on the “DEPOSIT” and chose your best way to deposit (Credit Card/Debit card, UPI or PayTM).
                    </p>
                    <p>
                      You can deposit any amount between the minimum and maximum deposit limit.
                    </p>
                  </div>
                </FaqQuestion>
                <FaqQuestion title="How Do I Validate My Account To Get My KYC Approved?">
                  <div>
                    <p>
                      You can get your KYC updated to get your account verified to withdraw on PokerSaint, by following the below steps:
                    </p>
                    <p>
                      -	Click on the “CASHIER” option in the app or web app.
                    </p>
                    <p>
                      -	Click on the “CASHOUT” option and click on the VERIFY option.
                    </p>
                    <p>
                      -	Enter your BANK details and upload a copy of your PAN and Aadhar. Once you click on SUBMIT, the team will check and verify your account within 24 hours.
                    </p>
                  </div>
                </FaqQuestion>
                <FaqQuestion title="Can I play on more than one table?">
                  <div>
                    <p>
                      You can play a maximum of 3 tables on the mobile app and as many tables as you want on the desktop app.
                    </p>
                  </div>
                </FaqQuestion>
                <FaqQuestion title="What variants of poker is available on PokerSaint?">
                  <div>
                    <p>
                      You can chose from No Limit Texas Holdem, Omaha, 5-card Omaha and Open-face Chinese poker.
                    </p>
                  </div>
                </FaqQuestion>
                <FaqQuestion title="I found players Chip Dumping/ Collusion on tables; how do I report it?">
                  <div>
                    <p>
                      We encourage our players to report any such instances on tables. To fasten the process, we request you to take a screenshot when you see players chip dumping/collusion on tables and report the same to our support team by clicking the SUPPORT option in the app or website and share the details. The team will check and take the necessary action.
                    </p>
                  </div>
                </FaqQuestion>
                <FaqQuestion title="Where can I find my tournament ticket?">
                  <div>
                    <p>
                      Your tickets are reflected in the CASHIER page.
                    </p>
                  </div>
                </FaqQuestion>
                <FaqQuestion title="Can I exchange or cash out a tournament ticket?">
                  <div>
                    <p>
                      Tickets are available only for specific tournaments. They cannot be exchanged for another tournament ticket or cashed out.
                    </p>
                  </div>
                </FaqQuestion>
                <FaqQuestion title="What is the expiry of a tournament ticket?">
                  <div>
                    <p>
                      All tickets have the expiry date mentioned on them. Tickets once expired or not used will disappear from your cashier page and cannot be issued again.
                    </p>
                  </div>
                </FaqQuestion>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
