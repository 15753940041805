export async function getPromotions() {
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer p8g0s5tcwz1qnqibpszco93rp36ec7mk",
		},
	};
	console.log("Get Promotions");
	const res = await fetch(
		`https://pokersaintadmin.rototransindia.com/api/promotion`,
		requestOptions
	);
	const resjson = await res.json();
	console.log(resjson);
	if (!res.ok) throw new Error(res.statusText);
	return resjson;
}

export async function getPromotionDetails(id) {
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer p8g0s5tcwz1qnqibpszco93rp36ec7mk",
		},
	};
	console.log("Get Promotion Details");
	const res = await fetch(
		`https://pokersaintadmin.rototransindia.com/api/promotion?id=${id}`,
		requestOptions
	);
	const resjson = await res.json();
	console.log(resjson);
	if (!res.ok) throw new Error(res.statusText);
	return resjson;
}

export async function getTestimonial() {
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer p8g0s5tcwz1qnqibpszco93rp36ec7mk",
		},
	};
	console.log("Get testimonial");
	const res = await fetch(
		`https://pokersaintadmin.rototransindia.com/api/testimonial`,
		requestOptions
	);
	const resjson = await res.json();
	console.log(resjson);
	if (!res.ok) throw new Error(res.statusText);
	return resjson;
}

export async function getBanners() {
	console.log("getbanner");
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer p8g0s5tcwz1qnqibpszco93rp36ec7mk",
		},
	};
	console.log("Get Banners");
	const res = await fetch(
		`https://pokersaintadmin.rototransindia.com/api/banner`,
		requestOptions
	);
	const resjson = await res.json();
	console.log(resjson);
	if (!res.ok) throw new Error(res.statusText);
	return resjson;
}

export async function getBlogs() {
	console.log("getbanner");
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer p8g0s5tcwz1qnqibpszco93rp36ec7mk",
		},
	};
	console.log("Get Banners");
	const res = await fetch(
		`https://pokersaintadmin.rototransindia.com/api/blogs`,
		requestOptions
	);
	const resjson = await res.json();
	console.log(resjson);
	if (!res.ok) throw new Error(res.statusText);
	return resjson;
}

export async function getBlogDetails(id) {
	console.log("getbanner");
	var url = "https://pokersaintadmin.rototransindia.com/api/blogs?";
	if(isNaN(id)){
		url += `pid=${id}`;
	}
	else{
		url += `id=${id}`;
	}
	console.log(url);
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer p8g0s5tcwz1qnqibpszco93rp36ec7mk",
		},
	};
	console.log("Get blog details");
	const res = await fetch(
		url,
		requestOptions
	);
	const resjson = await res.json();
	console.log(resjson);
	if (!res.ok) throw new Error(res.statusText);
	return resjson;
}
export async function getTournamentLeaderboardDetails(promoid, tourid) {
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer p8g0s5tcwz1qnqibpszco93rp36ec7mk",
		},
	};
	console.log("Get Tournament leaderboard Details");
	const res = await fetch(
		`https://pokersaintadmin.rototransindia.com/api/tourdetail?promoid=${promoid}&tourid=${tourid}`,
		requestOptions
	);
	const resjson = await res.json();
	console.log(resjson);
	if (!res.ok) throw new Error(res.statusText);
	return resjson;
}

export async function getLeaderboardDetails(promoid, leaderboardid) {
	console.log("get Leader board query initiate");
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer p8g0s5tcwz1qnqibpszco93rp36ec7mk",
		},
	};
	console.log("Get Leaderboard Details");
	const res = await fetch(
		`https://pokersaintadmin.rototransindia.com/api/cashleaderboard?promoid=${promoid}&leaderboardid=${leaderboardid}`,
		requestOptions
	);
	const resjson = await res.json();
	console.log(resjson);
	if (!res.ok) throw new Error(res.statusText);
	return resjson;
}

export async function getRaceDetails(promoiden, otheriden) {
	console.log("****** racedetails ******");
	console.log("Get Race Details for success");

	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer p8g0s5tcwz1qnqibpszco93rp36ec7mk",
		},
	};
	const res = await fetch(
		`https://pokersaintadmin.rototransindia.com/api/racedetail?promoid=${promoiden}&raceid=${otheriden}`,
		requestOptions
	);
	const resjson = await res.json();
	console.log(resjson);
	if (!res.ok) throw new Error(res.statusText);
	return resjson;
}

export async function getSocialDetails(promoid, stakeid){
	console.log("****** social Leaderboard ******");
	console.log("Get Race Details for success");

	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer p8g0s5tcwz1qnqibpszco93rp36ec7mk",
		},
	};
	const res = await fetch(
		`https://pokersaintadmin.rototransindia.com/api/socialleaderboard?promoid=${promoid}&stake=${stakeid}`,
		requestOptions
	);
	const resjson = await res.json();
	console.log(resjson);
	if (!res.ok) throw new Error(res.statusText);
	return resjson;
}

export async function getLiveTournamentDetails(promoiden, otheriden) {
	console.log("Get Live Tournament Details");
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer p8g0s5tcwz1qnqibpszco93rp36ec7mk",
		},
	};
	const res = await fetch(
		`https://pokersaintadmin.rototransindia.com/api/tournament`,
		requestOptions
	);
	const resjson = await res.json();
	console.log(resjson);
	if (!res.ok) throw new Error(res.statusText);
	return resjson;
}

export async function getOffers() {
	console.log("Get Live Tournament Details");
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer p8g0s5tcwz1qnqibpszco93rp36ec7mk",
		},
	};
	const res = await fetch(
		`https://pokersaintadmin.rototransindia.com/api/offers`,
		requestOptions
	);
	const resjson = await res.json();
	console.log(resjson);
	if (!res.ok) throw new Error(res.statusText);
	return resjson;
}
