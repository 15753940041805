import React from "react";
import a1 from '../assets/images/no-limit-texas-holdem.jpg';
export default function NoLimitTexasHoldem() {
  return (
    <div
      className="sidebar-page-container container"
      style={{ marginTop: "5%" }}
    >
      <div className="auto-container">
        <div className="row">
          <h1 className="color-give">Pokersaint.com - Online Poker Platform</h1>
        </div>
        <h5>
          <a href="index.aspx">Home</a> / No-Limit-Texas-Holdem-Poker
        </h5>
        <br />
        <div className="row abou">
          <img
            src={a1}
            alt="Play Limit Omaha"
            className="img-responsive"
            style={{ width: "auto", height: "300px" }}
          />
        </div>
        <br />
        <div className="row clearfix pt-5 mt-4">
          {/* <!--Content Side--> */}
          <div className="content-side col-lg-8 col-md-8 col-sm-12 breadcrumb">
            <div className="services-single">
              <h2>No Limit Texas Hold’em Poker</h2>
              <div className="text">
                <p>
                  Texas Hold’em Poker is played using the standard 52 card deck.
                  The players need’s to make the best possible combination of 5
                  cards.
                </p>
              </div>

              {/* <!-- Services Gallery --> */}

              <h2>Game play</h2>
              <br />
              <ul className="ter">
                <li>
                  The two players to the left of the dealer put out blind call.
                  The player directly to the dealer's left puts out the small
                  blind, while the player two to the dealer's left puts out the
                  big blind, which is twice as much as the small blind. The
                  small blind and big blind are compulsory. This is to ensure
                  there is some minimum amount during the course of the hand.
                </li>
                <li>
                  Every player is dealt two cards, face down. These are called
                  ‘hole cards’.
                </li>
                <li>
                  The action, or the first move, falls on the player to the left
                  of the big blind. They can either call the blind, raise it, or
                  fold. The size of a raise must be at least twice the size of
                  the bet preceding it; the maximum depends on the amount of
                  money the player has. The calling then continues around the
                  table.
                </li>
                <li>
                  After the calling round is completed, three cards are dealt
                  face up in the centre of the table, which is referred to as
                  the board. The first three cards in Texas Hold'em are called
                  the flop. These cards are “community cards” meaning everyone
                  can (and will need to) use at least three of them in
                  combination with their own hole cards to make the best hand.
                </li>
                <li>
                  From the flop on, calling begins with the player to the
                  dealer’s left, who can check or bet. The players after must
                  either check or bet if no one has bet yet; or they must call,
                  raise, or fold if someone has.
                </li>
                <li>
                  A fourth card is dealt face up onto the board. This is called
                  fourth street or the turn card. This is followed by another
                  round of calling.
                </li>
                <li>
                  The final card is dealt face up. This card is also called
                  fifth street or the river. A final round of calling occurs.
                  The remaining players show their cards and the person who can
                  make the best five-card hand by combining their pocket cards
                  with the cards on the board wins.
                  <br />
                  <b> Note:</b> In some rare cases in Texas Hold'em, the five
                  cards making up the board will actually be the best hand, in
                  which case everyone left in the hand divides up the poker.
                </li>
                <li>
                  The deal passes to the next player to the left (who was small
                  blind last hand) and a new hand is dealt.
                </li>
              </ul>
              <p>
                And now you know all the basic rules to Texas Hold'em poker!
              </p>
              <br />
              <br />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <h3 className="text-center">Latest News</h3>
            <div
              className="col-lg-12"
              style={{ border: "1px solid #000", padding: "15px" }}
            >
              <ul>
                {/* <?php
                     if(!empty($blog_rs1))
                     {
                        $i=1;
                        foreach ($blog_rs1 as $key) { ?>

                        <li style={{padding: "10px 0"}}><a href="<?= MAIN_DIR ?>blog-detail/<?= $key['blog_id'] ?>/<?= space_fill($key['blog_title']) ?>/"><?= custom_echo($key['blog_title'],60); ?></a></li>

                    <?php  
                       if($i == 10)
                        {
                          break;
                        }
                           $i++;  
                           }
                        
                     }

                      ?>
                      */}

                <li>Blog Details</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
