import React from "react";
import "../Promotions.scss";

export default function PromotionsBanner({ title }) {
  return (
    <div className="container-fluid ">
      <div className="promotions-banner">
        <h1 className="promotions-heading">{title}</h1>
      </div>
    </div>
  );
}
