import React from "react";
import { useQuery } from "react-query";
import LoadingElement from "../components/LoadingElement";
import { getLiveTournamentDetails } from "../queryFunctions";

export default function LiveTournament() {
  const { data, isLoading, isError, error } = useQuery(
    "liveTournament",
    getLiveTournamentDetails
  );
  if (isLoading) {
    return <LoadingElement />;
  }
  if (isError) {
    return <div>OOPS!! Something went wrong</div>;
  }
  return (
    <div className="container-sm">
      <div className="main-head">
        <h2>Pokersaint Upcoming Tournament</h2>
      </div>
      <div className="row">
        <div className="live-tournament col-lg-11 col-xl-9">
          <table className="mx-auto">
            <thead>
              <tr>
                <th>#</th>
                <th>ID</th>
                <th>Tournament Name</th>
                <th>Game Type</th>
                <th>Start Date Time IST</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr key={item.id}>
                    <td>1</td>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.type}</td>
                    <td>{item.start_date}</td>
                    <td>{item.status}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
