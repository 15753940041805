import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  expired,
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  promotion,
} from "../components/Images";
import LoadingElement from "../components/LoadingElement";
import { getBlogs, getPromotions } from "../queryFunctions";

export default function Blogs() {
  const { data, isLoading, isError, error } = useQuery("blogs", getBlogs);

  const linkUpdate = (id,link) => {
    console.log(id,link);
    return link != null ? link : id;
  }

  if (isLoading) {
    return <LoadingElement />;
  }
  if (isError) {
    return <div>OOPS!! Something went wrong</div>;
  }
  return (
    <>
      <div className="container-fluid">
        <div id="myCarousel" className="carousel slide" data-ride="carousel">
          {/* <!-- Wrapper for slides --> */}
          <div className="carousel-inner">
            <div className="item active" style={{ borderRadius: "15px" }}>
              <img
                src={promotion}
                alt="Los Angeles"
                style={{ width: "100%", borderRadius: "15px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="deposit-content promotion-content">
        <div className="container px-5">
          <div className="main-head">
            <h2>Promotions</h2>
          </div>
          <div className="row justify-content-center justify-content-md-start">
            {data.data.map((item) => (
              <div className="col-10 col-md-6 col-lg-4" key={item.blog_id}>
                <div
                  className="prom overflow-hidden d-flex flex-column  align-items-stretch"
                  style={{ height: "90%" }}
                >
                  <div className="deposit-img">
                    <img
                      alt=""
                      src={item.blog_url}
                      className="img-response promotion-image-max-height"
                    />
                  </div>
                  <div
                    className="prom-con d-flex flex-column  px-4 py-4 "
                    style={{ background: "#ffffff" }}
                  >
                    <h3 className="my-auto py-3 text-capitalize">
                      {item.blog_title}
                    </h3>
                    <p class="text-truncate">{item.blog_short_description}</p>
                    <Link
                      to={`/blogs/${linkUpdate(item.blog_id,item.blog_link)}`}
                      className="my-auto py-3 "
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
