import React from "react";
import "./static.scss";

export default function PrivacyPolicy() {
  return (
    <section style={{ marginTop: "5%" }} className="container">
      <div className="sidebar-page-container">
        <div className="auto-container">
          <div className="row clearfix">
            <h5>
              <a href="index.aspx">Home</a> / Privacy Policy
            </h5>
            {/* <!--Content Side--> */}
            <div className="content-side col-lg-12 col-md-12 col-sm-12 breadcrumb p-5 mt-4">
              <div className="services-single">
                <h4>Privacy Policy for Users </h4>
                <h5>INTRODUCTION</h5>
                <div className="text">
                  <p>
                    The Company (as defined in the User Agreement applicable to
                    you), offers online services (collectively, the “Services”).
                    The Company forms part of Pacific Gaming Private Limited
                    (the "Company", "we", "our" or "us").
                  </p>

                  <p>
                    The Company is deeply committed to safeguard the privacy
                    expectations of its users (“User(s)”, “you” or “your”).
                    Accordingly, we have put in place this Privacy Policy, which
                    outlines our data protection practices, including how we
                    collect, use, disclose and protect your Personal
                    Information, as well as your rights with respect to your
                    Personal Information.{" "}
                  </p>

                  <p>In this Privacy Policy, you can learn about:</p>
                  <ul className="ulls">
                    <li>WHAT INFORMATION WE COLLECT?</li>
                    <li>CONDITIONS FOR PROCESSING OF PERSONAL INFORMATION</li>
                    <li>MINORS</li>
                    <li>HOW WE USE THE COLLECTED INFORMATION?</li>
                    <li>MARKETING</li>
                    <li>WITH WHOM WE SHARE INFORMATION?</li>
                    <li>INTERNATIONAL TRANSFER OF INFORMATION</li>
                    <li>THIRD PARTY TRACKING TECHNOLOGIES</li>
                    <li>THIRD PARTY SERVICES</li>
                    <li>RETENTION OF PERSONAL INFORMATION</li>
                    <li>YOUR RIGHTS</li>
                    <li>HOW WE KEEP YOUR INFORMATION SECURED</li>
                    <li>CHANGES TO THE PRIVACY POLICY</li>
                    <li>HOW TO CONTACT US</li>
                  </ul>

                  <p>
                    We encourage you to read the Privacy Policy carefully and
                    use it to make informed decisions. By visiting the website,
                    mobile apps or other online properties, or by creating an
                    account through our Services, you hereby indicate that you
                    have read and understood this Privacy Policy.
                  </p>

                  <h4>WHAT INFORMATION WE COLLECT?</h4>
                  <p>We collect two types of information from our Users:</p>
                  <p>
                    <span>Personal Information :</span> The first type of
                    information which we collect is information that identifies
                    or may reasonably identify an individual reasonable effort
                    (“Personal Information”). Personal Information which is
                    being gathered may consist of the following:
                  </p>

                  <p>
                    <span>Account details :</span> When you open an account and
                    register to use the Services, you will be asked to provide
                    us certain details about yourself, such as: first and last
                    name, email address, gender, birth date, personal numeric
                    code, age, physical address, ID number, fiscal code, phone
                    number and occupation . In addition, we may collect certain
                    information as required under various regulationsgambling
                    regulations , as well as Know Your Client (“KYC”) and
                    Anti-Money Laundering (“AML”) regulatory requirements, such
                    as your source of funds.
                  </p>

                  <p>
                    <span>o Governmental-issued ID</span> In certain instances,
                    you will be required to provide a copy of a
                    governmental-issued ID, for identity verification process.
                    Please note that this information is required for certain
                    features of our Services.
                  </p>

                  <p>
                    <span>Gaming information :</span> In order for us to provide
                    the Services, we automatically record different information
                    related to your account and use of our Services, including
                    with respect to your transactions, deposits, balances,
                    withdraws, bonuses and winnings. We also collect information
                    regarding potential gambling problems or addictions, based
                    on your patterns of gaming across our services, in order to
                    ensure a responsible gaming environment and as required by
                    law.
                  </p>

                  <p>
                    <span>Financial information :</span> In order for you to
                    enjoy all the functions of the Services (e.g. wagering,
                    perform purchases, play in tournaments, etc.), payment
                    information will be collected from you, such as your
                    currency, credit card number and bank account details.
                  </p>

                  <p>
                    <span>Voluntary information :</span> We also collect
                    information which you provide us voluntarily, such as
                    information provided by our VIP customers. For example, when
                    you respond to communications from us, communicate with us
                    via email or share additional information about yourself
                    through your use of the Services, such as on the chat
                    feature and in games. This includes information you are
                    willing to share with us for the purpose of improving and
                    developing our relationship with you, as well as for
                    maintaining your stratification from our services (for
                    example, information about your marital status and family,
                    your hobbies and interests, and other information you share
                    with us through surveys).
                  </p>

                  <p>
                    <span>Device information :</span> We collect specific types
                    of connection details and information with regard to your
                    device, software or hardware that may identify you, such as:
                    device’s unique identifiers (e.g. UDID, IMEI and MAC
                    address), browser fingerprinting, IP address and
                    geo-location data.
                  </p>

                  <p>
                    <span>Telephone calls :</span> We record or monitor
                    telephone calls for customer support, training and/or
                    security purposes.
                  </p>

                  <p>
                    <span>Events :</span> during our events, we conduct
                    interview and take photos and videos, which may feature
                    attendees, speakers, sponsors or exhibitors. Where photos
                    and videos are taken that feature you as an attendee,
                    speaker, sponsor or exhibitor, we may use those photos and
                    videos for promotional purposes. We may also use your
                    interview for promotional purposes (such as by quoting you).
                  </p>

                  <p>
                    <span>Social networks :</span> When you register through
                    your social network account (such as your Facebook account),
                    to use the Services or connect your player account with such
                    account, we will have access to basic information from your
                    social network account, such as your name, birthdate,
                    profile picture and friends' list, as well as information
                    you made publicly available on such account.
                  </p>
                  <p>
                    In addition, when using the Services, you may be able via
                    your social network to "Invite a Friend" to use the
                    Services. Should the person accept your invitation, we may
                    obtain Personal Information from him/her such as: name,
                    email address, phone number and date of birth. We will use
                    the Personal Information for the purposes set out herein and
                    disclose such information only to third parties as detailed
                    in this Privacy Policy.
                  </p>

                  <p>
                    Information we collect from third parties: We collect
                    Personal Information from third party service providers,
                    such as information about your credit history from credit
                    agencies and other financial information which is relevant
                    to the provision of the Services, as well as information
                    which is gathered in order to verify your identity and
                    prevent fraudulent or illegal activity.
                  </p>

                  <h2>Non-personal Information</h2>
                  <p>
                    The second type of information is un-identified and
                    non-identifiable information pertaining to a User(s), which
                    may be made available or gathered via the User’s use of the
                    Services (“Non-Personal Information”). Non-Personal
                    Information which is being collected consists of technical
                    information and aggregated usage information, and may
                    contain, among other things, the User’s operating system,
                    type of browser, screen resolution, browser and keyboard
                    language, the User’s ‘click-stream’ and activities on the
                    Services, the period of time the User visited the Services
                    and related time stamps, etc.
                  </p>

                  <p>
                    <b>
                      For avoidance of doubt, any Non-personal Information
                      connected or linked to any Personal Information shall be
                      deemed as Personal Information as long as such connection
                      or linkage exists.
                    </b>
                  </p>

                  <p>
                    <b>
                      Types of Non-personal Information we collect from or about
                      you:
                    </b>
                  </p>
                  <p>
                    <span>Log-in history and technical information :</span> In
                    order to enhance the functionality of the Services and to
                    provide you with a better user experience, we collect
                    technical information transmitted by your device, including
                    certain software and hardware information (e.g. the type of
                    browser and operating system your device uses, language
                    preference, access time and the domain name of the website
                    from which you linked to the Services; etc.).
                  </p>
                  <p>
                    <span>Gameplay information :</span> We record game play
                    information including, amongst other things, your deposits,
                    bonuses, game session duration and your high scores. We may
                    also share and publish such information through any of our
                    Platforms.
                  </p>

                  <p>
                    <span>Device and connection information :</span> If you
                    download the Software to your device, we may collect
                    information from the particular device you are using, for
                    security and fraud detection and prevention purposes. For
                    example, we may gather information with regard to other
                    software which is running simultaneously with the Software
                    for detecting if you are using software which is associated
                    with fraudulent activity (e.g. robots, malware, etc.) or
                    checking if the connection you are using is via a VPN or
                    proxy.
                  </p>

                  <p>
                    <span>Analytics information : </span> We collect information
                    about your use of the Services, such as applications' usage,
                    log files, user activity (e.g. pages viewed, the amount of
                    time spent on particular pages, online browsing, clicks,
                    actions, etc.), time stamps, alerts, etc. This information
                    is collected for amongst other things troubleshooting errors
                    and bugs as well as for research and analytics purposes
                    about your use of the Services.
                  </p>

                  <p>
                    <span>Anonymous information</span> We may anonymize or
                    de-identify the information collected by the Services or via
                    other means so that the information cannot, on its own,
                    personally identify you. Our use and disclosure of such
                    aggregated or de-identified information is not subject to
                    any restrictions under this Privacy Policy, and we may
                    disclose it to others without limitation and for any
                    purpose, such as for advertising or marketing purposes.
                  </p>

                  <h2>CONDITIONS FOR PROCESSING PERSONAL INFORMATION</h2>
                  <p>
                    We will process your Personal Information for a variety of
                    reasons, each of which is prescribed by relevant data
                    protection laws.
                  </p>
                  <p>
                    <span>
                      Fulfillment of a contract, compliance with a legal
                      obligation :
                    </span>
                    It is necessary for us to process your Personal Information
                    where it is necessary for the performance of a contract
                    (such as for the User Agreement) or in order for us to
                    comply with our various legal and/or regulatory
                    responsibilities, including, but not limited to, complying
                    with the conditions of our licenses and complying with any
                    AML and KYC legislation.
                  </p>

                  <p>
                    <span>Legitimate interests :</span>We also process your
                    Personal Information where we deem such processing to be in
                    our (or a third party's) legitimate interests and provided
                    always that such processing will not prejudice your
                    interests, rights and freedoms. Examples of us processing in
                    accordance with legitimate interests would include: (i)
                    where we disclose your Personal Information to any one or
                    more of our associate/subsidiary companies following a
                    restructure or for internal administrative purposes; (ii)
                    detection and retention of information pertaining to those
                    with responsible gaming issues; (iii) processing for the
                    purposes of ensuring network and information security,
                    including preventing unauthorized access to our electronic
                    communications network; (iv) safeguarding the integrity of
                    our Services by combatting, reporting and sharing
                    information related to suspicious calling patterns or
                    fraudulent activities; (v) adhering to regulatory and
                    statutory requirements; (vi) devising a tailored reward
                    scheme for players; and (vii) sharing personal information
                    with our advisers and professional services providers (such
                    as auditors) for ensuring our compliance with regulatory
                    requirements and industry best practices.
                  </p>

                  <p>
                    <span>Special Categories of Personal Data :</span>Our
                    processing of your Personal Information may also involve
                    special categories of personal data, such as your racial or
                    ethnic origin. We will process such information, as well as
                    disclose it to competent authorities (such as licensing
                    bodies or law enforcement bodies), where it is necessary for
                    the following purposes (to the extent permissible by
                    applicable law): (i) prevention or detection of an unlawful
                    act, (i) prevention of dishonesty, malpractice or other
                    seriously improper conduct, provided that obtaining your
                    consent may prejudice those purposes. In addition, we may
                    process and disclose such information where it is necessary
                    for the purposes of safeguarding your (or others’) economic
                    well-being (such as detection and retention of information
                    pertaining to those with responsible gaming issues).
                  </p>

                  <h2>MINORS</h2>
                  <p>
                    The Services are not designed or directed to persons under
                    the age of 18 with respect to the use of the Services
                    (“Legally of Age”). If you are not Legally of Age, you
                    should not download or use the Services nor provide any
                    Personal Information to us.
                  </p>
                  <p>
                    We reserve the right to access and verify any Personal
                    Information collected from you. In the event that we become
                    aware that a user who is not Legally of Age has shared any
                    information, we may discard such information unless it is
                    needed to comply with any legal or statutory obligation
                    binding upon us. If you have any reason to believe that a
                    minor has shared any information with us, please contact us
                    (our contact details are available under the "HOW TO CONTACT
                    US?" section).
                  </p>

                  <h2>HOW WE USE THE COLLECTED INFORMATION?</h2>
                  <p>
                    We use your Personal Information for the purposes listed
                    below:
                  </p>
                  <ul className="ulls">
                    <li>To set up, manage and update your account;</li>
                    <li>
                      To provide and operate the Services (such as for calling
                      and payment processing);
                    </li>
                    <li>
                      To communicate with you and to keep you informed of our
                      latest updates to our Services and special offers;
                    </li>
                    <li>To determine your VIP status;</li>
                    <li>
                      To market our Services (see more below under “Marketing”),
                      as well as to serve you advertisements, including
                      behavioral advertising;
                    </li>
                    <li>
                      To conduct analytics, statistical and research purposes,
                      in order to improve and customize the Services to your
                      needs and interests (such as by compiling aggregated
                      reports about the usage of certain features of our
                      Services);
                    </li>
                    <li>
                      For customer relationship management purposes, and to
                      support and troubleshoot the Services and to respond to
                      your queries;
                    </li>
                    <li>
                      To enable us to further develop, customize and improve the
                      Services based on Users’ common preferences and uses;
                    </li>
                    <li>
                      To provide you with a responsible gaming environment;
                    </li>
                    <li>
                      To communicate with your and to process any of your
                      requests to exercise your User Rights;
                    </li>
                    <li>
                      To identify and authenticate your access to certain
                      features of the Services;
                    </li>
                    <li>
                      To detect and prevent fraudulent and illegal activity or
                      any other type of activity that may jeopardize or
                      negatively affect the integrity of the Services, including
                      by identifying risks associated with your activity on our
                      Services;
                    </li>
                    <li>
                      To investigate violations of our policies and User
                      Agreement as well as enforce our policies and the User
                      Agreement;
                    </li>
                    <li>
                      To investigate and resolve disputes in connection with
                      your use of the Services;
                    </li>
                    <li>
                      To assist us with meeting our regulatory obligations or as
                      required by law or regulation (such as KYC and AML
                      regulatory requirements), including for the purpose of
                      ascertaining your source of funds or income, or as
                      required by other governmental authorities, or to comply
                      with a legal process or respond to a government request;
                      and
                    </li>
                    <li>
                      In addition, we use your Personal Information upon your
                      specific and informed consent, such as for certain
                      marketing and promotional activities.
                    </li>
                  </ul>

                  <p>
                    Where you have not consented (or have withdrawn your
                    consent) to the processing of your Personal Information by
                    us, we may continue to process your Personal Information (a)
                    where processing is required for the performance of the
                    contract (i.e. the User Agreement) between you and us;
                    and/or (b) where processing is necessary for compliance with
                    a legal obligation to which we are subject; (c) where
                    processing is necessary for the purposes of a legitimate
                    interest of the Company (including for marketing).
                  </p>

                  <h2>MARKETING</h2>
                  <p>
                    The Company will use your Personal Information, such as your
                    name, home address, email address, telephone number etc.,
                    ourselves or by using our third party subcontractors for the
                    purpose of providing you with promotional materials,
                    concerning the Services as well as products, services,
                    websites and applications which relate to: (i) other
                    companies (ii) or (ii) the Company’s business partners and
                    affiliates (collectively: “Marketing Affiliates”), which we
                    believe may interest you.
                  </p>

                  <p>
                    We may also share and disclose Personal Information with our
                    Marketing Affiliates for the purpose of providing you
                    different marketing offers, which we, or our Marketing
                    Affiliates, believe are relevant for you. Our Marketing
                    Affiliates may use this Personal Information for different
                    marketing techniques, such as direct email, post, SMS and
                    telephone marketing purposes.
                  </p>

                  <p>
                    We will use your Personal Information for the purpose of
                    providing you with promotional materials solely where we
                    have a legitimate interest in doing do, or where we have
                    obtained your affirmative consent.
                  </p>

                  <p>
                    You may at any time decline receiving further marketing
                    offers from us or from Marketing Affiliates by either:
                    following the guidelines available on the marketing
                    communications, (such as selecting the opt-out link inserted
                    in the promotional emails) or by contacting support (our
                    contact details are available under the "HOW TO CONTACT US?"
                    Section). Please note that even if you unsubscribe from our
                    marketing mailing list, we may continue to send you
                    service-related updates and notifications.
                  </p>

                  <p>
                    <span>Please note: </span> you can control the delivery of
                    certain advertising or social campaigns through the settings
                    offered by the respective third party platforms (e.g.
                    Facebook).
                  </p>
                  <p>
                    In addition, if you download any of our mobile applications
                    to your device from the Apple AppStore or Google Play, the
                    only way to prevent receipt of notifications is by changing
                    the settings on the device itself.
                  </p>

                  <h3>TARGETED ADVERTISING</h3>
                  <p>
                    We use third-party advertising technology to serve
                    advertisements when you visit the Platform and use the
                    Services. You may opt-out of many third-party ad networks,
                    including those operated by opting for the “Do Not Disturb”
                    feature using your network provider.
                  </p>

                  <h3>WITH WHOM WE SHARE INFORMATION?</h3>
                  <p>
                    We do not rent, sell, or share your Personal Information
                    with third parties (“Recipients”) except as described in
                    this Privacy Policy. The Personal Information will be
                    disclosed to Recipients only to the extent required for the
                    specific purpose, as stipulated in this Privacy Policy.
                  </p>
                  <p>
                    We share Personal Information with any of the following
                    recipients:
                  </p>
                  <ul className="ulls">
                    <li>
                      Any replacement service provider that we engage with to
                      operate the Platforms;
                    </li>
                    <li>Other affiliated companies;</li>
                    <li>Gaming providers;</li>
                    <li>
                      Subcontractors and third party service providers, as well
                      as their subcontractors, which by way of example include
                      (but is not limited to) cloud computing companies,
                      marketing affiliates, identity verification and fraud
                      prevention services, and other data verifiers;
                    </li>
                    <li>
                      Credit reporting agencies and credit reference bureaus,
                      including for conducting name, address and age checks.
                    </li>
                    <li>
                      Payment service providers, payment processors and banks;
                    </li>
                    <li>
                      Any third parties who provides services in relation to the
                      operation or promotion of the applicable company’s brands;
                    </li>
                    <li>
                      Any third party who provides you with a tangible prize;
                    </li>
                    <li>
                      Any third parties who organize offline events or
                      tournaments on behalf of or in conjunction with the
                      company;
                    </li>
                    <li>
                      Hotels and flight companies (such as in the context of
                      offline events and promotions);
                    </li>
                    <li>
                      Auditors, contractors or legal/financial/other advisers of
                      any of the Company’s business processes;
                    </li>
                    <li>
                      Any third parties who investigate, detect or prevent
                      fraudulent or illegal activity or enable us to enforce our
                      policies, including in order to ascertain your source of
                      income or funds (e.g. governmental authorities, law
                      enforcement bodies, banks and other investigatory bodies);
                    </li>
                    <li>Gambling addictions bodies;</li>
                    <li>
                      Licensing authorities, governmental and regulatory bodies,
                      in accordance with applicable laws and regulations; and
                    </li>
                    <li>
                      Potential purchasers, successors or investors within the
                      company, or in the event of a corporate transaction (e.g.
                      sale of a substantial part of our business, merger,
                      reorganization, bankruptcy, consolidation or asset sale of
                      an asset or transfer in the operation thereof) in relation
                      to the company{" "}
                    </li>
                    <li>
                      Storing such information on our behalf, for example by
                      using cloud computing service providers;
                    </li>
                    <li>
                      Processing such information to assist us with our business
                      operations (e.g. to process payments and your deposits;
                      authenticate your access; auditing our operations; detect
                      and prevent fraudulent or illegal activity; etc.);
                    </li>
                    <li>
                      Performing research, technical diagnostics or analytics;
                    </li>
                    <li>
                      Communicating targeted advertising, as well as promotional
                      and informational materials, in accordance with our
                      marketing policy (see above, in the section titled
                      "Marketing"); and
                    </li>
                    <li>
                      Whenever we believe in good faith that disclosure is
                      necessary to protect our rights or legal claims, enforce
                      our policies (including our User Agreement and Privacy
                      Policy), protect your safety or the safety of others, as
                      well as to investigate or prevent any fraud, for security
                      reasons or to help us with any other related technical
                      issue.
                    </li>
                  </ul>

                  <h2>THIRD PARTY TRACKING TECHNOLOGIES</h2>
                  <p>
                    When you visit or access our Services (for example when you
                    visit our websites), we use (and authorize third parties to
                    use) web beacons, cookies, pixels, scripts, tags and other
                    technologies <b>("Tracking Technologies").</b>
                  </p>
                  <p>
                    The Tracking Technologies allow us to automatically collect
                    information about you and your online behavior, as well as
                    your device (for example your computer or mobile device),
                    for different purposes, such as in order to enhance your
                    navigation on our Services, improve our Services’
                    performance and customize your experience on our Services.
                    We also use this information to collect statistics about the
                    usage of our Services, perform analytics, deliver content
                    which is tailored to your interests and administer services
                    to our Users, advertisers, publishers, customers and
                    partners.
                  </p>
                  <p>
                    We also allow third parties to collect information about you
                    through Tracking Technologies.{" "}
                  </p>

                  <h2>THIRD PARTY SERVICES</h2>
                  <p>
                    While using the Services you may encounter links to third
                    party websites, services or applications. Please keep in
                    mind that this Privacy Policy does not apply to any third
                    party websites, services or applications, even if they are
                    accessible, downloadable, or otherwise distributed through
                    the Services.
                  </p>
                  <p>
                    Please be advised that such third party websites, services
                    or applications are independent from the Company. We assume
                    no responsibility or liability whatsoever with regard to
                    privacy matters or any other legal matter with respect to
                    such third party websites and/or services. We encourage you
                    to carefully read the privacy policies and the terms of use
                    of such third party websites and/or services, as their
                    terms, not ours, will apply to any of your interactions with
                    such third parties.
                  </p>
                  <p>
                    You should always review their privacy practices carefully
                    before providing Personal Information to such third parties.
                  </p>
                  <p>
                    You are knowingly and voluntarily assuming all risks of
                    using any third-party websites, services or applications.
                    You agree that we shall have no liability whatsoever with
                    respect to such third party sites and your usage of them.
                  </p>

                  <h2>RETENTION OF PERSONAL INFORMATION</h2>
                  <p>
                    If you have registered with an account through our Services,
                    the Company will retain your Personal Information during the
                    period your account is active. In addition, the Company will
                    retain your Personal Information for additional periods, as
                    strictly necessary to enable the Company to meet its legal
                    obligations under applicable laws or regulations, such as
                    the applicable KYC and AML regulations, as well as to meet
                    the Group’s contractual obligations.
                  </p>
                  <p>
                    In addition, the Group may retain your Personal Information
                    for longer periods, provided that retaining such information
                    is strictly necessary for the company’s legitimate
                    interests, such as fraud prevention and record keeping,
                    responsible gaming reasons, resolving or exercising claims
                    regarding potential disputes, and where the company is
                    guided to do so by the applicable supervisory authority.
                  </p>

                  <h2>YOUR RIGHTS</h2>
                  <p>
                    You may contact us at any time by email (our contact details
                    are available under the "HOW TO CONTACT US?" section), and
                    request:
                  </p>
                  <ul className="ulls">
                    <li>
                      To access or delete any Personal Information relating to
                      you;
                    </li>
                    <li>
                      To change or update any Personal Information relating to
                      you (for example, if you believe that your Personal
                      Information is incorrect, you may ask to have it corrected
                      or deleted). Note that you may also request that we will
                      correct errors with regard to your Personal Information
                      (except in cases where the information is required to be
                      kept in its original format under any applicable laws and
                      regulations);
                    </li>
                    <li>
                      That we will restrict or cease any further use of your
                      Personal Information;
                    </li>
                    <li>
                      That we will provide the Personal Information you
                      volunteered to us in a machine-readable format;
                    </li>
                    <li>
                      To object to the processing of your Personal Information
                      (such as for marketing purposes);
                    </li>
                    <li>
                      To withdraw your consent to our processing activities
                      (provided that such processing activities rely on your
                      consent, and not on a different legal basis);
                    </li>
                    <li>
                      To not be subject to a decision based solely on automated
                      processing, including profiling, which produces legal
                      effects concerning you or similarly significantly affects
                      you, except where such processing is necessary for the
                      performance of the contract between you and us, or it is
                      based on your explicit consent, as provided hereunder.
                    </li>
                  </ul>
                  <p>
                    Please note that these rights are not absolute and requests
                    are subject to any applicable legal requirements, including
                    other legal and ethical reporting or document retention
                    obligations. We may also rectify, replenish or remove
                    incomplete or inaccurate information, at any time and at our
                    own discretion, in accordance with our internal policies.
                  </p>

                  <h2>HOW WE KEEP YOUR INFORMATION SECURED</h2>
                  <p>
                    We take great care in implementing and maintaining the
                    security of the Services and your information. We have put
                    in place appropriate physical and technological safeguards
                    to help prevent unauthorized access, to maintain data
                    security, and to use correctly the information we collect
                    online. These safeguards vary based on the sensitivity of
                    the information that we collect and store.
                  </p>
                  <p>
                    We employ industry standard procedures and controls to
                    ensure the safety of our users’ information, such as:
                  </p>
                  <ul className="ulls">
                    <li>
                      Secure network topology, which includes intrusion
                      prevention and Firewall systems;
                    </li>
                    <li>Encrypted communication;</li>
                    <li>Authentication and Access Control;</li>
                    <li>External and Internal audit tests; etc.</li>
                    <p>
                      Although we take reasonable steps to safeguard
                      information, we cannot be responsible for the acts of
                      those who gain unauthorized access or abuse the Services,
                      and we make no warranty, express, implied or otherwise,
                      that we will prevent such access.
                    </p>
                  </ul>

                  <h2>CHANGES TO THE PRIVACY POLICY</h2>
                  <p>
                    We reserve the right to change this Privacy Policy at any
                    time, so please re-visit this page frequently. We will
                    provide notice of substantial changes of this Privacy Policy
                    on the Services and/or we will send you an e-mail regarding
                    such changes to the applicable e-mail address that you
                    provided to us. Such substantial changes will take effect
                    fourteen (14) days after such notice was provided on any of
                    the above mentioned methods. Otherwise, all other changes to
                    this Privacy Policy are effective as of the stated “Last
                    Revised” date, and your continued use of the Services after
                    the Last Revised date will constitute acceptance of, and
                    agreement to be bound by, those changes.
                  </p>

                  <h2>HOW TO CONTACT US?</h2>
                  <p>
                    If you have any general questions about the Services or the
                    information that we collect about you and how we use it,
                    please contact us through one of the companies detailed
                    below, as explained below:
                    <br />
                    Email:
                    <a href="mailto:care@pokersaint.com">
                      <span className="__cf_email__">
                        [email&#160;protected]
                      </span>
                    </a>
                    We will make an effort to reply within a reasonable
                    timeframe. Please feel free to reach out to us at any time.
                  </p>
                  <p>
                    The Privacy Policy has been drafted in the English language.
                    In the event of any discrepancy between the meanings of any
                    translated versions of the Privacy Policy and the English
                    language version, the meaning of the English language
                    version shall prevail, to the extent permissible by domestic
                    law in the jurisdiction you reside in.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
