import React from "react";
import a1 from '../assets/images/no-limit-texas-holdem.jpg';
export default function OpenFaceChinese() {
  return (
    <div
      className="sidebar-page-container container"
      style={{ marginTop: "5%" }}
    >
      <div className="auto-container">
        <div className="row">
          <h1 className="color-give">Pokersaint.com - Online Poker Platform</h1>
        </div>
        <h5>
          <a href="index.aspx">Home</a> / Open-Face-Chinese-Poker
        </h5>
        <br />
        <div className="row abou">
          <img
            src={a1}
            alt="Play Limit Omaha"
            className="img-responsive"
            style={{ width: "auto", height: "300px" }}
          />
        </div>
        <br />
        <div className="row clearfix pt-5 mt-4">
          {/* <!--Content Side--> */}
          <div className="content-side col-lg-8 col-md-8 col-sm-12 breadcrumb">
            <div className="services-single">
              <h2>Open Face Chinese Poker</h2>
              <div className="text">
                <p>
                Open Face Chinese Poker (OFC) refers to a very popular poker game, where instead of utilizing chips like in the traditional poker games it uses a certain point system with each point having a certain value and helping in determining the outcome. The most common way to play OFC is Pineapple OFC, which is played between 2 to 3 players.
                </p>
              </div>

              {/* <!-- Services Gallery --> */}

              <h2>How To Play Pineapple OFC</h2>
              <br />
              <ul className="ter">
                <li>
                  In Pineapple OFC each player is dealt 5 cards (Face-up) initially and in the successive rounds 3 cards (Face-down)each, out of which one card is to be discarded, making it a total of 13 cards to make the best hand possible by adjusting them in a 3,5,5 pattern (Top Hand, Middle Hand & Bottom Hand).
                </li>
                <li>
                  Once each player sets his/her hands, each hand of a player is compared to the respective hand of the opponent. (Top- Top hand, Middle- Middle Hand & Bottom- Bottom Hand) and the scores are calculated to determine the winner as well as the winning amount.
                </li>
                <li>
                  It is to be kept in mind while adjusting the cards that the Bottom Hand has to be stronger than Middle Hand and the middle one has to be stronger than the top hand. Failing to do so results in a Dead Hand losing the hand by default.
                </li>
                <li>
                  In a 3-way game, the action moves in a clock-wise way starting from the left of the dealer just like in Regular Poker.
                </li>
                <li>
                  Basic Points System- Here each row consists of 1 point. Now if a player beats his opponent in all three rows (Top, Middle & Bottom Hand), he/she scores a +6 point, which is called a “Scoop”. Beating the opponent in two rows provides a +1 point and only in one row provides a –1 point. Losing in all three rows obviously gives a point of –6.
                </li>
                <li>
                  Royalty points- Apart from the Basic Points system, there are Royalty points (referred as Bonus Points too) which are gained as additional points for strong hands like Royal, Straight Flush, Quads, Full House, Flush, Straight, Trips etc. Please note that the royalty points are awarded regardless whether the player wins or losses in that particular row/hand and can negate each other (For example- If both players have flush in their bottom rows/hands, then the royalty point=4 awarded to each of them will negate each other and the one with the higher flush will win that row/hand with +1 point). The Royalty points are mentioned below for all sort of hand-strengths-
                </li>
              </ul>
              <table className="intable" style={{width:"100%"}} >
                <tbody>
                  <tr>
                    <td >
                    <p>Hand Strength</p>
                    </td>
                    <td >
                    <p>Bottom Hand</p>
                    </td>
                    <td >
                    <p>Middle Hand</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>Trips</p>
                    </td>
                    <td >
                    <p>0 Points</p>
                    </td>
                    <td >
                    <p>2 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>Straight</p>
                    </td>
                    <td >
                    <p>2 Points</p>
                    </td>
                    <td >
                    <p>4 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>Flush</p>
                    </td>
                    <td >
                    <p>4 Points</p>
                    </td>
                    <td >
                    <p>8 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p >Full House</p>
                    </td>
                    <td >
                    <p>6 Points</p>
                    </td>
                    <td >
                    <p>12 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>Quads</p>
                    </td>
                    <td >
                    <p>10 Points</p>
                    </td>
                    <td >
                    <p>20 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>Straight Flush</p>
                    </td>
                    <td >
                    <p>15 Points</p>
                    </td>
                    <td >
                    <p>30 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>Royal Flush</p>
                    </td>
                    <td >
                    <p>25 Points</p>
                    </td>
                    <td >
                    <p>50 Points</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <p style={{marginLeft:"1rem"}}><br/><strong>Top Hand</strong><br/></p>

              <table className="intable" style={{width:"100%"}}>
                <tbody>
                  <tr>
                    <td >
                    <p>Hands</p>
                    </td>
                    <td >
                    <p>Pair</p>
                    </td>
                    <td >
                    <p>Trips</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>2s</p>
                    </td>
                    <td >
                    <p>0 Points</p>
                    </td>
                    <td >
                    <p>10 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>3s</p>
                    </td>
                    <td >
                    <p>0 Points</p>
                    </td>
                    <td >
                    <p>11 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>4s</p>
                    </td>
                    <td >
                    <p>0 Points</p>
                    </td>
                    <td >
                    <p>12 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>5s</p>
                    </td>
                    <td >
                    <p>0 Points</p>
                    </td>
                    <td >
                    <p>13 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>6s</p>
                    </td>
                    <td >
                    <p>1 Points</p>
                    </td>
                    <td >
                    <p>14 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>7s</p>
                    </td>
                    <td >
                    <p>2 Points</p>
                    </td>
                    <td >
                    <p>15 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>8s</p>
                    </td>
                    <td >
                    <p>3 Points</p>
                    </td>
                    <td >
                    <p>16 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>9s</p>
                    </td>
                    <td >
                    <p>4 Points</p>
                    </td>
                    <td >
                    <p>17 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>10s</p>
                    </td>
                    <td >
                    <p>5 Points</p>
                    </td>
                    <td >
                    <p>18 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>Js</p>
                    </td>
                    <td >
                    <p>6 Points</p>
                    </td>
                    <td >
                    <p>19 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>Qs</p>
                    </td>
                    <td >
                    <p>7 Points</p>
                    </td>
                    <td >
                    <p>20 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>Ks</p>
                    </td>
                    <td >
                    <p>8 Points</p>
                    </td>
                    <td >
                    <p>21 Points</p>
                    </td>
                  </tr>
                  <tr>
                    <td >
                    <p>As</p>
                    </td>
                    <td >
                    <p>9 Points</p>
                    </td>
                    <td >
                    <p>22 Points</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <br/>
                <strong>Fantasyland in Pineapple OFC-</strong>
                <br/>
              </p>
              <p>As the name says, it is literally the fantasy of any player in Pineapple OFC. When a player manages to set Queens or better in the top row/hand without fouling the hand (Dead/Invalid hand), he/she qualifies for a Fantasyland which implies that in the subsequent hand/round he/she gets dealt 17 cards (Face-up) at once to set his/her best hands whereas the opponent is dealt with the cards in the regular fashion (5+3+3+3+3). Now, the player can get a “Recurring Fantasy” if he/she manages to set Quads or better in the bottom row or trips in the top row in the fantasy hand. There is no limit on the number of recurring fantasy a player can get.</p>
              <br />
              <br />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <h3 className="text-center">Latest News</h3>
            <div
              className="col-lg-12"
              style={{ border: "1px solid #000", padding: "15px" }}
            >
              <ul>
                {/* <?php
                     if(!empty($blog_rs1))
                     {
                        $i=1;
                        foreach ($blog_rs1 as $key) { ?>

                        <li style={{padding: "10px 0"}}><a href="<?= MAIN_DIR ?>blog-detail/<?= $key['blog_id'] ?>/<?= space_fill($key['blog_title']) ?>/"><?= custom_echo($key['blog_title'],60); ?></a></li>

                    <?php  
                       if($i == 10)
                        {
                          break;
                        }
                           $i++;  
                           }
                        
                     }

                      ?>
                      */}

                <li>Blog Details</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
