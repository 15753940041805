import React from "react";
import "./static.scss";

export default function DisconnectionPolicy() {
  return (
    <>
      <section style={{ marginTop: "5%" }}>
        <div className="sidebar-page-container container">
          <div className="auto-container">
            <div className="row abou">
              {/* <img
                src="images/Disconnection_image.png"
                style={{ width: "100%", height: "300px" }}
              /> */}
            </div>
          </div>
        </div>
      </section>

      {/* <!--Sidebar Page Container--> */}
      <div className="sidebar-page-container container">
        <div className="auto-container">
          <div className="row clearfix">
            <h5>
              <a href="index.aspx">Home</a> / Disconnection Policy
            </h5>
            <br />
            <br />
            {/* <!--Content Side--> */}
            <div className="content-side col-lg-12 col-md-12 col-sm-12 p-5 mt-4">
              <div className="services-single">
                <h4>Disconnection Policy </h4>
                <div className="text">
                  <ul className="ter">
                    <li>
                      Pokersaint makes the best effort to ensure that all
                      players are offered an even gaming experience. Should any
                      player face disconnection issues during a game, the
                      following pollicies shall apply.
                    </li>
                    <li>
                      Players that are disconnected from the game server during
                      any call they have committed to the shall be retained
                      in the game until their turn is up next. Any player that
                      does not reconnect by that time will be folded and the
                      turn will go to the next player.
                    </li>
                    <li>
                      Players must accept the risk of disconnection, and should
                      simply log back in and continue playing as quickly as
                      possible if they are cut off. Pokersaint does not accept
                      any risk for disconnection of a player.
                    </li>
                    <li>
                      As soon as the software detects a disconnection, it will
                      make every attempt to reconnect to the server and return
                      the player to the game table they were seated at. If the
                      internet connection has been lost, there is no possibility
                      that the player may be able to connect at all.
                    </li>
                    <li>
                      Pokersaint reserves the right to alter all policies in
                      this regard and any disputes in this regard will be
                      resolved by management. The decision in this case will be
                      final and binding.
                    </li>
                    <li>
                      If a player who is deemed to be disconnected has the
                      option to check on his turn, our software will
                      automatically carry out this action and the player will be
                      retained in the game.
                    </li>
                    <li>
                      Every player is allocated a bonus time of 60 seconds every
                      2 hours. If the player disconnects, the server will wait
                      for 60 seconds or the remaining bonus time after the
                      regular time to act. If the player does not reconnect or
                      return to the table within this time, the player will be
                      folded automatically. If the player has used up all bonus
                      time, then they must wait for the server to topup the time
                      at intervals decided by Pokersaint
                    </li>
                    <li>
                      Disconnection protection as explained in step 6 is not
                      available in Sit N Go and Multi table Tournaments.
                    </li>
                  </ul>
                </div>

                <h5>Cancellation Policy</h5>
                <ul className="ter">
                  <li>
                    Due to the immediate nature of business, Pacific Gaming does
                    not allow cancellation of a transaction. If a user would
                    like a refund of a transaction due to human error, please
                    email us on{" "}
                    <a href="/cdn-cgi/l/email-protection#f596948790b5859a9e908786949c9b81db969a98">
                      {" "}
                      <span
                        className="__cf_email__"
                        data-cfemail="553634273015253a3e302726343c3b217b363a38"
                      >
                        [email&#160;protected]
                      </span>
                    </a>{" "}
                    and we will ensure that your request is processed within
                    24-48 banking hours.
                  </li>
                  <li>
                    Pokersaint, at its discretion, may decide to cancel any
                    tournament without prior notice.
                  </li>
                  <li>
                    In the event of a buy-in tournament, the entire amount of
                    the buy-in and fees paid by the user to participate in the
                    tournament will be refunded fully as long as no payouts have
                    been awarded.
                  </li>
                  <li>
                    Should a tourney be canceled midway, all refunds will be at
                    the discrecion of Pokersaint based on the following factors.
                  </li>
                  <ul className="subter">
                    <li>
                      If a tourney has had payouts made, then the balance amount
                      will be distributed equally to the remaining players.
                    </li>
                    <li>
                      If a tourney has had payouts made, then the payouts will
                      be canceled and the entire amount will be refunded to each
                      player.
                    </li>
                    <li>
                      The choice of which option to use will be at the
                      discretion of Pokersaint at all times.
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
