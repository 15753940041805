import React from "react";
import { bannerMobile, slider1, slider2, slider3 } from "../components/Images";
import { Carousel } from "react-bootstrap";
export default function BannerCarousel({ data }) {
  console.log("BannerCarousel");
  console.log(data);
  return (
    <div className="container-fluid px-5 ">
      <Carousel
        indicators={false}
        pause={false}
        nextIcon={
          <div
            className="right carousel-control"
            href="#myCarousel"
            data-slide="next"
          >
            <span className="glyphicon glyphicon-chevron-right"></span>
            <span className="sr-only">Next</span>
          </div>
        }
        prevIcon={
          <a
            className="left carousel-control"
            href="#myCarousel"
            data-slide="prev"
          >
            <span className="glyphicon glyphicon-chevron-left"></span>
            <span className="sr-only">Previous</span>
          </a>
        }
      >
        {/* <!-- Wrapper for slides --> */}

        {data.map((item, index) => (
          <Carousel.Item key={item.slider_id}>
            <img
              src={item.slider_image_url}
              alt={item.slider_alt}
              className="d-none d-lg-block"
              style={{ width: "100%" }}
            />
            <img
              src={item.slider_mobile_image}
              alt={item.slider_alt}
              className="d-lg-none"
              style={{ width: "100%" }}
            />
          </Carousel.Item>
        ))}
        {/* <div className="item active">
            <img src={slider1} alt="Chicago" style={{ width: "100%" }} />
          </div>
          <div className="item ">
            <img src={slider2} alt="Chicago" style={{ width: "100%" }} />
          </div>

          <div className="item">
            <img src={slider3} alt="New york" style={{ width: "100%" }} />
          </div> */}

        {/* <!-- Left and right controls --> */}
      </Carousel>
    </div>
  );
}
