import { faq } from "../components/Images";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import { useState } from "react";
import { Link } from "react-router-dom";

const FaqQuestion = ({ title, children }) => {
  const [openQuestion, setOpenQuestion] = useState(false);

  return (
    <div className="mb-4 ">
      <div className="d-flex align-items-center mb-2">
        <h4 className="font-weight-bold font-weight-bold my-auto">{title}</h4>
        <div
          className="faq-button "
          onClick={() => setOpenQuestion(!openQuestion)}
        >
          {openQuestion ? (
            <FaChevronDown size="1.3rem" color="white" />
          ) : (
            <FaChevronRight size="1.3rem" color="white" />
          )}
        </div>
      </div>
      {openQuestion ? children : null}
      <div style={{ borderBottom: "1px solid" }} className="mt-4" />
    </div>
  );
};

export default function Faq() {
  return (
    <>
      <div className="container-fluid px-5 d-none d-lg-block">
        <div id="myCarousel" className="carousel slide" data-ride="carousel">
          {/* <!-- Wrapper for slides --> */}
          <div className="carousel-inner">
            <div className="item active" style={{ borderRadius: "15px" }}>
              <img
                src={faq}
                alt="Los Angeles"
                style={{ width: "100%", borderRadius: "15px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="deposit-content promotion-content">
        <div className="container px-5">
          <div
            className="main-head d-none d-lg-block"
            style={{ borderBottom: "1px solid" }}
          >
            <h2>POKER FAQ’S</h2>
          </div>
          <div className="main-head d-block d-lg-none">
            <h2>POKER FAQ’S</h2>
          </div>
          <div className="d-lg-none">
            <img src={faq} alt="deposit mobile" style={{ width: "100%" }} />
          </div>

          <div className="row" style={{ paddingTop: "60px" }}>
            <div>
              <FaqQuestion title="How Do I Start Playing On Poker Saint?">
                <div class="acc-content current">
                  <div class="content">
                    <p>
                      PokerSaint Poker Games can be played on several devices!
                      Choose what’s best for you and start playing NOW for
                      FREE!!
                    </p>
                    <p>
                      - Instant Play: Click on the "PLAY NOW" button on the top
                      of the page to start playing instantly!
                    </p>
                    <p>
                      - Android Application: On the Home page, scroll to the
                      "ANDROID DOWNLOAD" section and click on the button to
                      download the latest App.
                    </p>
                    <p>
                      - iOS Application: Visit the istore and search for
                      “PokerSaint” to download it directly from the istore!{" "}
                    </p>
                    <p>
                      - Windows and MAC desktop: On the Home page, scroll to the
                      "DESKTOP DOWNLOAD" section and click on the button to
                      download the latest desktop App.
                    </p>
                  </div>
                </div>
              </FaqQuestion>

              <FaqQuestion title="What Are The Rules For Omaha?">
                <div class="acc-content">
                  <div class="content">
                    <p>
                      Please view our page{" "}
                      <Link to="/pot-limit-omaha">Omaha</Link>
                    </p>
                  </div>
                </div>
              </FaqQuestion>
              <FaqQuestion title="What Are The Rules Of Texas Hold’em?">
                <div class="acc-content">
                  <div class="content">
                    <p>
                      Please view our page{" "}
                      <Link to="/no-limit-texas-holdem-poker">
                        Texas Hold’em
                      </Link>
                    </p>
                  </div>
                </div>
              </FaqQuestion>
              <FaqQuestion title="What Are The Rules Of OFC?">
                <div class="acc-content">
                  <div class="content">
                    <p>
                      Please view our page{" "}
                      <Link to="/open-face-chinese-poker">
                        Open Face Chinese (OFC)
                      </Link>
                    </p>
                  </div>
                </div>
              </FaqQuestion>
              <FaqQuestion title="How Do I Make A Deposit?">
                <div>
                  <p>
                    To deposit money in your PokerSaint account to play Poker. All you need to do is follow these simple steps:
                  </p>
                  <p>
                    - Click on the “CASHIER” option in the app or web app.
                  </p>
                  <p>
                    -	Click on the “DEPOSIT” and chose your best way to deposit (Credit Card/Debit card, UPI or PayTM).
                  </p>
                  <p>
                    You can deposit any amount between the minimum and maximum deposit limit.
                  </p>
                </div>
              </FaqQuestion>
              <FaqQuestion title="How Do I Validate My Account To Get My KYC Approved?">
                <div>
                  <p>
                    You can get your KYC updated to get your account verified to withdraw on PokerSaint, by following the below steps:
                  </p>
                  <p>
                    -	Click on the “CASHIER” option in the app or web app.
                  </p>
                  <p>
                    -	Click on the “CASHOUT” option and click on the VERIFY option.
                  </p>
                  <p>
                    -	Enter your BANK details and upload a copy of your PAN and Aadhar. Once you click on SUBMIT, the team will check and verify your account within 24 hours.
                  </p>
                </div>
              </FaqQuestion>
              <FaqQuestion title="Can I play on more than one table?">
                <div>
                  <p>
                    You can play a maximum of 3 tables on the mobile app and as many tables as you want on the desktop app.
                  </p>
                </div>
              </FaqQuestion>
              <FaqQuestion title="What variants of poker is available on PokerSaint?">
                <div>
                  <p>
                    You can chose from No Limit Texas Holdem, Omaha, 5-card Omaha and Open-face Chinese poker.
                  </p>
                </div>
              </FaqQuestion>
              <FaqQuestion title="I found players Chip Dumping/ Collusion on tables; how do I report it?">
                <div>
                  <p>
                    We encourage our players to report any such instances on tables. To fasten the process, we request you to take a screenshot when you see players chip dumping/collusion on tables and report the same to our support team by clicking the SUPPORT option in the app or website and share the details. The team will check and take the necessary action.
                  </p>
                </div>
              </FaqQuestion>
              <FaqQuestion title="Where can I find my tournament ticket?">
                <div>
                  <p>
                  Your tickets are reflected in the CASHIER page.
                  </p>
                </div>
              </FaqQuestion>
              <FaqQuestion title="Can I exchange or cash out a tournament ticket?">
                <div>
                  <p>
                  Tickets are available only for specific tournaments. They cannot be exchanged for another tournament ticket or cashed out.
                  </p>
                </div>
              </FaqQuestion>
              <FaqQuestion title="What is the expiry of a tournament ticket?">
                <div>
                  <p>
                  All tickets have the expiry date mentioned on them. Tickets once expired or not used will disappear from your cashier page and cannot be issued again.
                  </p>
                </div>
              </FaqQuestion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
