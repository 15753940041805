import React from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import {Helmet} from "react-helmet";
import {
  expired,
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  promotion,
} from "../components/Images";
import LoadingElement from "../components/LoadingElement";
import { getBlogDetails, getBlogs, getPromotions } from "../queryFunctions";

export default function BlogDetails() {
  const {id}=useParams()
  const { data, isLoading, isError, error } = useQuery(
   [ "blogDetails",id],
    ()=>getBlogDetails(id)
  );

  if (isLoading) {
    return <LoadingElement />;
  }
  if (isError) {
    return <div>OOPS!! Something went wrong</div>;
  }
  return (
    <div>
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={data.data.detail.seo_description}/>
          <meta name="keywords" content={data.data.detail.seo_keywords}/>
          <title>{data.data.detail.blog_title}</title>
          <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="container-fluid px-5 d-none d-lg-block">
        <div id="myCarousel" className="carousel slide" data-ride="carousel">
          {/* <!-- Wrapper for slides --> */}
          <div className="carousel-inner">
            <div className="item active" style={{ borderRadius: "15px" }}>
              <img
                src={data.data.detail.blog_url}
                alt="Los Angeles"
                style={{ width: "100%", borderRadius: "15px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container px-4 py-5">
          <div className="main-head">
            <h2>{data.data.detail.blog_title}</h2>
          </div>
          <img
            src={data.data.detail.blog_url}
            alt="Los Angeles"
            style={{ width: "100%", borderRadius: "15px" }}
            className="d-lg-none"
          />
        </div>
      </div>
      <div className="blog-content">
        <div
          dangerouslySetInnerHTML={{
            __html: data.data.detail.blog_description,
          }}
        />
      </div>
    </div>
  );
}
