import { faq } from "../components/Images";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Tds() {
  return (
    <>
      <div className="container">
        <h1 className="mb-4"> TDS Policy Changes for Online Game Winnings </h1>
        <p>
            As per the government of India, new TDS policy rules will apply from April 1, 2023, to the online game
            winnings of all users. The old TDS policy before March 31, 2023, imposed TDS on the winnings from online
            games exceeding ₹10,000. However, after the introduction of the new rules, online gaming platforms will levy
            a 30% TDS on the net winnings of users upon withdrawal.
        </p>
        <p>
            If you play real money games online, here’s everything you need to know about the new TDS policy.
        </p>
        <h1 className="mb-4"> What is TDS?</h1>

        <p> TDS implies Tax Deducted at Source. According to the latest government policies, the online game winnings of
            users playing real money games on an online gaming platform will be subject to tax deductions. The TDS will
            be deducted only from the Net Winnings of the users.</p>
        <h1 className="mb-4"> Old TDS Policy </h1>
        <p>According to the old TDS Policy before 31 March 2023, any winnings above ₹9999 were liable to TDS deductions.
            There was no TDS imposed on withdrawals below ₹10,000. For instance, if a user wins ₹12,000 (which is above
            the ₹10,000 threshold) in an online game, there will be a TDS of ₹3,600 (30% on 12,000), and the user will
            receive ₹8,400 upon withdrawal.</p>
        <h1 className="mb-4"> New TDS Policy</h1>
        <p>According to the new TDS Policy, all net winnings are liable to TDS deductions of 30% at the time of
            withdrawals w.e.f April 1, 2023. Net winnings exclude all the deposit amounts, opening balances, and
            withdrawals on which TDS is already paid from your total Withdrawals for the entire financial year.</p>
        <h1 className="mb-4"> Important TDS Terms</h1>
        <p>Based on the above formula of net winnings, we can calculate the amount of TDS that will be deducted. Let’s
            take an example:</p>
        <h1 className="mb-4"> Calculation of Net Winnings and TDS</h1>
        <p>Based on the above formula of net winnings, we can calculate the amount of TDS that will be deducted. Let’s
            take an example:
        </p>
        <p className="fw-bold">Net winnings = ( Total withdrawals in FY-(Total Deposits in FY+Net Internal Deposit+ Opening
            Balance as of
            1st April 00:00))</p>
        <p>Example:</p>
        <table className="table table-bordered">
            <thead>
                <tr className="table-primary">
                    <th scope="col">Transactions</th>
                    <th scope="col">Day 1</th>
                    <th scope="col">Day 2</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Deposit</td>
                    <td>100</td>
                    <td>200</td>
                </tr>
                <tr>
                    <td>Withdrawal</td>
                    <td>0</td>
                    <td>1000</td>
                </tr>
                <tr>
                    <td>Opening Balance</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>Net Internal Deposit</td>
                    <td>0</td>
                    <td>900</td>
                </tr>
                <tr>
                    <td>Liable/Not</td>
                    <td>-100</td>
                    <td>-100</td>
                </tr>
                <tr>
                    <td>Player A wants to withdraw</td>
                    <td>1000</td>
                    <td>2000</td>
                </tr>
                <tr>
                    <td>Actuall amount which TDS is calculated</td>
                    <td>900</td>
                    <td>1900</td>
                </tr>
                <tr>
                    <td>TDS 30%</td>
                    <td>270</td>
                    <td>570</td>
                </tr>
                <tr>
                    <td>Net amt transferred to Player</td>
                    <td>730</td>
                    <td>1430</td>
                </tr>

            </tbody>
        </table>
        <h1 className="mb-4">TDS Policy on <Link to="https://www.pokersaint.com/">www.pokersaint.com</Link> and its related applications:</h1>
        <p>
            Following the new TDS Policy w.e.f April 1, 2023, www.pokersaint.com and it's applications will also deduct
            the prescribed TDS on all net winnings. www.pokersaint.com or it's holding company will issue relavent TDS
            certificate('s) against deductions made or against deductions liable as per the new TDS policy during a
            financial year. This certificate will help users file their Income Tax returns with the CBD and claim tax
            refunds (if tax is deducted or paid in excess). To pay taxes against your ID, www.pokersaint.com will have
            your PAN card/ PAN number on our system. Any amount filed by the company against your PAN number will
            reflect on your Form 26AS, which will be updated within 30 days of the end of every quarter (For example, if
            it is the June quarter, we will file the TDS for users by the end of July and so on).
        </p>
        <h1>FAQs</h1>
        <h4 className="my-3">How much tax will be deducted as per the new TDS Policy?</h4>
        <p>As per the new TDS Policy, a 30% tax on the net winnings from an online real money game will be deducted upon
            withdrawal.</p>
        <h4 className="my-3">When is TDS Deducted?</h4>
        <p>TDS on all net winnings will be deducted only at the time of withdrawal.</p>
        <h4 className="my-3">Will every withdrawal of mine attract a TDS of 30%?</h4>
        <p>No, At every withdrawal, your net winnings are calculated, and TDS is charged only on profit amounts.</p>
        <h4 className="my-3">Is pokersaint charging this TDS?</h4>
        <p>No, the TDS charged will go to the Government of India, just like any other income tax.</p>
        <h4 className="my-3">How can I reduce my tax liability?</h4>
        <p>Avoid doing multiple transactions/withdrawals. Instead, try to accumulate the winnings and withdraw once a
            month. </p>
        <h4 className="my-3">My TDS has been deducted, will I get any TDS certificate for the same?</h4>
        <p>pokersaint will provide a TDS certificate every quarter in a financial year. </p>
        <h4 className="my-3">What if I end up paying extra TDS?</h4>
        <p>This can happen if a withdrawal is made when net winnings are positive and at the year-end, the user is in
            net losses. At the end of the financial year, 00:00 hrs on 31st March, your final TDS will be calculated
            depending on the financial year’s net winnings. Since the winnings earned from Online Gaming are liable for
            30% TDS, users need to file their ITR and the total TDS deducted can be adjusted against the Winnings liable
            for tax. If extra is deducted, it will be returned to user.
        </p>
      </div>
    </>
  );
}
