import React, { useState } from "react";
// import "./PromotionDetails.scss";
import "./PromotionRacesAndLeaderboards.scss";
import { promotionCarousel1 } from "../../components/Images";
import { Outlet, useParams } from "react-router-dom";
import PromotionsBanner from "../promotions/PromotionsBanner";
import { arrowLeft, arrowRight } from "../../components/Images";

import Carousel, { consts } from "react-elastic-carousel";
import { useQuery } from "react-query";
import {
  getLeaderboardDetails,
  getPromotionDetails,
  getRaceDetails,
} from "../../queryFunctions";
import LoadingElement from "../../components/LoadingElement";
const arrowImages = ({ type, onClick, isEdge }) => {
  const img = type === consts.PREV ? arrowLeft : arrowRight;
  return (
    <img
      src={img}
      style={{ width: "9px", height: "14px", margin: "auto" }}
      onClick={onClick}
      disabled={isEdge}
    />
  );
};
export default function LeaderboardOutlet() {
  const { id, raceId, leaderId, tourId, name } = useParams();
  const replacedName = name.split("-").join(" ");

  const breakPointsForRaces = [
    { width: 1, itemsToShow: 3 },
    { width: 568, itemsToShow: 5 },
    { width: 992, itemsToShow: 8 },
  ];

  const { data, isLoading, isError, error } = useQuery(["promotions", id], () =>
    getPromotionDetails(id)
  );
  const leaderboardAPI = useQuery(
    ["leaderboards", id, leaderId],
    () => getLeaderboardDetails(id, leaderId),
    {
      onSuccess: (data) => {
        console.log("leaderboard data", data);
      },
    }
  );

  // carouselArrayData &&
  //   setRaceDateHandler(
  //     carouselArrayData.uniqueDate[carouselArrayData.uniqueDate.length - 1]
  //   );

  if (leaderboardAPI.isLoading || isLoading) {
    return <LoadingElement />;
  }
  if (leaderboardAPI.isError || isError) {
    return <div>OOPS!! Something went wrong</div>;
  }
  return (
    <>
      <div className="row margin-top-leaderboard-section container-fluid">
        {/* NOTE:: change the justification to justify content between when you uncomment image */}
        <div className="d-flex flex-column-reverse flex-lg-row col-10 col-lg-11  mx-auto justify-content-center align-items-center">
          {/* <img
            // src={data.data.promo_detail.promotion_image}
            src={data.data.promo_detail.promotion_mobile_image}
            className="flex-basis-45 mr-4 mt-3 mt-sm-4 mt-lg-0 overlay-hidden"
            width={500}
          /> */}
          <div className="flex-basis-45 flex flex-column justify-content-center align-items-center px-4 my-auto">
            <p className="text-center leaderboard-name mb-0 mt-4 mt-sm-0">
              {leaderboardAPI.data.current_leaderboard[0].leaderboard_name
                .toLowerCase()
                .replace("leaderboard", "")}
            </p>
            <p className="text-center leadearboard-title"> LEADERBOARD</p>
          </div>
        </div>
      </div>
      {/* Table for leaderboard */}
      <div className="container-fluid row mx-auto table-margin-top">
        <div className="col-10 col-lg-11 mx-auto">
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>RANK</th>
                <th>NICKNAME</th>
                <th>POINTS</th>
              </tr>
            </thead>
            <tbody>
              {leaderboardAPI.data.leaderboard_data.length > 0 ? (
                leaderboardAPI.data.leaderboard_data.map((item) => (
                  <tr>
                    <td>{item.rank}</td>
                    <td>{item.player_name}</td>
                    <td>{item.points}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3}>There is no data to display</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
