import React, { useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { StackedCarousel } from "react-stacked-carousel";
import {
  promotionCarousel1,
  promotionCarousel2,
  promotionCarousel3,
  promotionSlider1,
  promotionSlider2,
  promotionSlider3,
} from "./Images";
import "./promotionsCarousel.scss";
export default function PromotionsCarousel({ data }) {
  const [card, setCard] = useState(null);
  const navigate = useNavigate();
  console.log("promotion carousel", data);
  const onCardChange = (event) => {
    // console.log("Card", event);
  };
  return (
    <div className="Promotion">
      <div className="container">
        <div className="main-head">
          <h2>Online Poker Game Promotions By Pokersaint</h2>
        </div>
        {data.filter((filtered) => filtered.promotion_status === "Active")
          .length > 2 ? (
          <StackedCarousel
            autoRotate={true}
            onCardChange={onCardChange}
            rotationInterval={5000}
            containerClassName={"promotions-carousel-container"}
            cardClassName="promotions-carousel-card"
            leftButton={<FiChevronLeft className="carousel-arrow" />}
            rightButton={<FiChevronRight className="carousel-arrow" />}
          >
            {data
              .filter((filtered) => filtered.promotion_status === "Active")
              .map((item) => (
                <div
                  key={item.promotion_id}
                  className="promotions-carousel-card"
                  onClick={() => navigate(`/promotion/${item.promotion_id}`)}
                >
                  <img
                    src={item.promotion_mobile_image}
                    className="d-lg-none"
                    alt="Promotion Image"
                  />
                  <img
                    src={item.promotion_image}
                    className="d-none d-lg-block"
                    alt="Promotion Image"
                  />
                </div>
              ))}
          </StackedCarousel>
        ) : (
          <div className="d-flex flex-col flex-lg-row mx-auto justify-content-evenly gap-4">
            {data
              .filter((filtered) => filtered.promotion_status === "Active")
              .map((item) => (
                <div
                  key={item.promotion_id}
                  className="promotions-carousel-card overflow-hidden"
                  onClick={() => navigate(`/promotion/${item.promotion_id}`)}
                >
                  <img
                    src={item.promotion_mobile_image}
                    className="d-lg-none stacked-carousel-image-size"
                    alt="Promotion Image"
                  />
                  <img
                    src={item.promotion_image}
                    className="d-none d-lg-block stacked-carousel-image-size"
                    alt="Promotion Image"
                  />
                </div>
              ))}
          </div>
        )}
        {/* Uncomment the code below to access api data */}

        <div className="prm-btn mt-5">
          <Link to="/promotions" type="button" className="btn btn-primary">
            VIEW ALL
          </Link>
        </div>
      </div>
    </div>
  );
}
