import React from "react";

const Rake = () => {
  return (
    <section style={{ marginTop: "5%" }} className="container">
      {/* <!--Sidebar Page Container--> */}
      <div class="sidebar-page-container">
        <div class="auto-container">
          <div class="row clearfix">
            {/* <!--Content Side--> */}
            <div class="content-side col-lg-12 col-md-12 col-sm-12">
              <div class="services-single">
                <h4 className="text-center m-4">RAKE STRUCTURE</h4>

                {/* <!--Services Info Tabs--> */}
                <div class="Services-info-tabs">
                  {/* <!--Service Tabs--> */}
                  <div class="">
                    {/* <!--Tabs Container--> */}
                    <div class="tabs-content">
                      {/* <!--Tab / Active Tab--> */}
                      <div class="tab active-tab" id="1">
                        <div class="content">
                          <div class="text">
                            <table
                              class="table table1"
                              cellpadding="0"
                              cellspacing="0"
                            >
                              <thead>
                                <tr>
                                  <th colspan="6">RAKE STRUCTURE</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Game Type</th>
                                  <th>Stakes</th>
                                  <th>Heads Up Rake %</th>
                                  <th>Heads Up Rake Cap</th>
                                  <th> Rake % (3 or more players)</th>
                                  <th>Rake Cap</th>
                                </tr>
                                <tr>
                                  <td rowspan="12">
                                    <b>OMAHA/ TEXAS/ RIT TEXAS/RIT OMAHA</b>
                                  </td>
                                  <td>0.5/1</td>
                                  <td>5.25</td>
                                  <td>16</td>
                                  <td>5.25</td>
                                  <td>27</td>
                                </tr>
                                <tr>
                                  <td>1/2.</td>
                                  <td>5.25</td>
                                  <td>32</td>
                                  <td>5.25</td>
                                  <td>48</td>
                                </tr>
                                <tr>
                                  <td>2/4.</td>
                                  <td>4.2</td>
                                  <td>65</td>
                                  <td>5.25</td>
                                  <td>80</td>
                                </tr>
                                <tr>
                                  <td>3/6.</td>
                                  <td>4.2</td>
                                  <td>90</td>
                                  <td>5.25</td>
                                  <td>115</td>
                                </tr>
                                <tr>
                                  <td>5/10.</td>
                                  <td>4.2</td>
                                  <td>105</td>
                                  <td>5.2</td>
                                  <td>180</td>
                                </tr>
                                <tr>
                                  <td>10/20.</td>
                                  <td>4.2</td>
                                  <td>160</td>
                                  <td>5.25</td>
                                  <td>260</td>
                                </tr>
                                <tr>
                                  <td>25/50.</td>
                                  <td>4.2</td>
                                  <td>315</td>
                                  <td>5.25</td>
                                  <td>525</td>
                                </tr>
                                <tr>
                                  <td>50/100.</td>
                                  <td>3.15</td>
                                  <td>420</td>
                                  <td>4.2</td>
                                  <td>600</td>
                                </tr>
                                <tr>
                                  <td>100/200.</td>
                                  <td>3.15</td>
                                  <td>630</td>
                                  <td>4.2</td>
                                  <td>735</td>
                                </tr>
                                <tr>
                                  <td>200/400.</td>
                                  <td>2.94</td>
                                  <td>790</td>
                                  <td>3.7</td>
                                  <td>1000</td>
                                </tr>
                                <tr>
                                  <td>500/1000</td>
                                  <td>2.94</td>
                                  <td>1260</td>
                                  <td>3.7</td>
                                  <td>1775</td>
                                </tr>

                                <tr>
                                  <td>1000/2000</td>
                                  <td>2.94</td>
                                  <td>1575</td>
                                  <td>3.7</td>
                                  <td>2300</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Rake;
