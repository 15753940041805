import { Link } from "react-router-dom";
import {
  f1,
  f2,
  f3,
  f4,
  facebook,
  instagram,
  logo,
  pokersaintMembers,
} from "./Images";
import { FaFacebookSquare } from "react-icons/fa";
import { RiInstagramLine } from "react-icons/ri";
import { useEffect } from "react";
import { useState } from "react";

export default function Footer() {
  const [stickyClass, setStickyClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", stickyFooter);
    return () => window.removeEventListener("scroll", stickyFooter);
  }, []);

  const stickyFooter = () => {
    if (window !== undefined) {
      let windowScrollHeight = window.innerHeight + window.scrollY;
      // window height changed for the demo
      windowScrollHeight < document.body.scrollHeight - 100
        ? setStickyClass("footer-bottom sticky-footer d-md-none z-20")
        : setStickyClass("d-none");
    }
  };
  const downloadButtonHandler = () => {
    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      //   window.location.href = 'https://files.pokersaint.com/downloads/PokerSaintAlpha/android/PokerSaint.apk';
      window.location.href =
        "https://files.pokersaint.com/downloads/PokerSaintAlpha/android/PokerSaint.apk";
    }
    if (navigator.userAgent.toLowerCase().indexOf("mac") > -1) {
      window.location.href =
        "https://files.pokersaint.com/downloads/mac/PokerSaintSetup.dmg";
    }
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      window.location.href =
        "https://apps.apple.com/in/app/poker-saint/id1294659165";
    }
    if (navigator.userAgent.toLowerCase().indexOf("iPod") > -1) {
      window.location.href =
        "https://apps.apple.com/in/app/poker-saint/id1294659165";
    }
    if (navigator.userAgent.toLowerCase().indexOf("iPad") > -1) {
      window.location.href =
        "https://apps.apple.com/in/app/poker-saint/id1294659165";
    }
    if (navigator.userAgent.toLowerCase().indexOf("webOS") > -1) {
      window.location.href =
        "https://apps.apple.com/in/app/poker-saint/id1294659165";
    }
    if (navigator.userAgent.toLowerCase().indexOf("Macintosh") > -1) {
      window.location.href =
        "https://apps.apple.com/in/app/poker-saint/id1294659165";
    }
    if (navigator.userAgent.toLowerCase().indexOf("windows") > -1) {
      window.location.href =
        "https://files.pokersaint.com/downloads/win/PokerSaintSetup.exe";
    }
  };

  const instagramRedirector = () => {
    window.open("https://www.instagram.com/pokersaint");
  };
  const facebookRedirector = () => {
    window.open("https://www.facebook.com/PokerSaintClub");
  };

  return (
    <>
      <section className="footer-section mt-30 px-5">
        <div className="container mx-auto">
          <div className="d-none d-lg-block row justify-content-center">
            <div className=" col-lg-12 footer-logo mx-auto">
              <img alt="" src={logo} className="main_logo img-fluid" />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-4  col-lg-3">
              <h3>Help Center </h3>
              <ul>
                <li>
                  <Link to="/achievements">Achievers</Link>
                </li>
                {/* <li>
                  <Link to="#">Contact us</Link>
                </li> */}
                <li>
                  <Link to="/blogs">Blog</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/tds-policy">TDS Policy</Link>
                </li>
                {/* <li>
                  <div className="media">
                    <img
                      alt=""
                      src={facebook}
                      className="img-response text-secondary"
                    />
                    <i className="fa-brands fa-instagram color-gray"></i>
                    <img alt="" src={instagram} className="img-response" />
                  </div>
                </li> */}
              </ul>
            </div>

            <div className="col-5 col-lg-3">
              <h3>Quick Links</h3>
              <ul>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-of-use">Terms and Conditions</Link>
                </li>
                <li>
                  <Link to="/disconnection-policy">Disconnection Policy</Link>
                </li>
                <li>
                  <Link to="/responsible-gaming">Responsible Gaming</Link>
                </li>
                {/* <li>
                  <Link to="/">Trademarks</Link>
                </li> */}
              </ul>
            </div>
            <div className="col-3 d-lg-none">
              <img alt="" src={logo} className="main_logo img-fluid" />
              <div className="d-flex flex-column align-items-end mt-4 gap-4 mr-4 pr-4">
                <RiInstagramLine
                  size={20}
                  color="#6D6D6D"
                  onClick={instagramRedirector}
                />
                <FaFacebookSquare
                  size={20}
                  color="#6D6D6D"
                  onClick={facebookRedirector}
                />
              </div>
            </div>
            <div className="col-4 col-lg-3 d-none d-lg-block">
              <h3>Member</h3>
              <ul>
                <li>
                  <img alt="" src={pokersaintMembers} />
                </li>
              </ul>
            </div>
            <div className="col-4 col-lg-3 d-none d-lg-block">
              <h3>Security</h3>
              <ul>
                <li>
                  <img alt="" src={f1} />
                </li>
                <li>
                  <img alt="" src={f2} />
                </li>
                <li>
                  <img alt="" src={f3} />
                </li>
              </ul>
            </div>
            <div className="row d-lg-none mt-4">
              <div className="col-4">
                <h3>Member</h3>
                <ul>
                  <li>
                    <img alt="" src={pokersaintMembers} className="img-fluid" />
                  </li>
                </ul>
              </div>
              <div className="col-8">
                <h3>Security</h3>
                <ul className="d-flex flex-row  justify-content-between align-items-center gap-2">
                  <li>
                    <img alt="" src={f1} className="img-fluid" />
                  </li>
                  <li>
                    <img alt="" src={f2} className="img-fluid" />
                  </li>
                  <li>
                    <img alt="" src={f3} className="img-fluid" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="footer mx-auto d-flex justify-space-between align-items-center">
              <h3 className="my-auto d-none d-lg-flex">Get Pokersaint App</h3>
              <button
                type="button"
                className="footer-download-button"
                onClick={downloadButtonHandler}
              >
                <span className="d-none d-lg-block">DOWNLOAD APP</span>
                <span className="d-lg-none ">DOWNLOAD APP NOW</span>
              </button>
              <img
                src={f4}
                className="img-response fot-img my-auto d-none d-md-inline"
                alt=""
              />
              <div className="star my-auto d-none d-md-flex flex-column ">
                <h5>PokerSaint</h5>
                <h5>
                  <i
                    className="fa fa-star"
                    style={{ color: "red", paddingRight: "5px" }}
                  ></i>
                  4.5
                </h5>
              </div>
              <div className="media my-auto d-none d-lg-flex gap-4">
                <RiInstagramLine
                  size={40}
                  color="#ffffff"
                  onClick={instagramRedirector}
                />
                <FaFacebookSquare
                  size={40}
                  color="#ffffff"
                  onClick={facebookRedirector}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={stickyClass}>
        <div className="container">
          <div className="row">
            <div className="footer mx-auto d-flex justify-space-between align-items-center">
              <h3 className="my-auto d-none d-lg-flex">Get Pokersaint App</h3>
              <button
                type="button"
                className="footer-download-button"
                onClick={downloadButtonHandler}
              >
                <span className="d-none d-lg-block">DOWNLOAD APP</span>
                <span className="d-lg-none">DOWNLOAD APP NOW</span>
              </button>
              <img
                src={f4}
                className="img-response fot-img my-auto d-none d-md-inline"
                alt=""
              />
              <div className="star my-auto d-none d-md-flex flex-column ">
                <h5>PokerSaint</h5>
                <h5>
                  <i
                    className="fa fa-star"
                    style={{ color: "red", paddingRight: "5px" }}
                  ></i>
                  4.5
                </h5>
              </div>
              <div className="media my-auto d-none d-lg-flex gap-4">
                <RiInstagramLine
                  size={40}
                  color="#ffffff"
                  onClick={instagramRedirector}
                />
                <FaFacebookSquare
                  size={40}
                  color="#ffffff"
                  onClick={facebookRedirector}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-bottom py-3 footer-copyright">
        <p className="text-center text-white">
          2023 © Pokersaint All rights reserved by{" "}
          <a href="https://www.thepacificgaming.com/" className="red-color">
            Pacific Gaming Private Limited
          </a>
        </p>
      </section>
    </>
  );
}
