import React from "react";
import "./static.scss";

export default function Leagality() {
  return (
    <div
      className="sidebar-page-container container"
      style={{ marginTop: "5%" }}
    >
      <div className="auto-container">
        <div className="row clearfix">
          <h5>
            <a href="index.aspx">Home</a> / Legality
          </h5>
          {/* <!--Content Side--> */}
          <div className="content-side col-lg-12 col-md-12 col-sm-12 breadcrumb p-5 mt-4">
            <div className="services-single">
              <h4>Legality</h4>
              <p>
                The structure of the games offered on Poker Saint, which is our
                intellectual property, is duly supported by legislations and
                judicial pronouncements prevailing in India. In order to ensure
                that the manner in which we are offering games on our website is
                legal. We at our part made sure that we are not violating any
                laws of the Indian constitution, by some of the respected law
                firms in India, for the safety and convenience of our players.
                Information is never enough.{" "}
              </p>

              <h4>POKER - A GAME OF SKILL</h4>
              <p>Meaning and differentiation from gambling</p>
              <p>
                Game of skill is defined as playing a game where luck has little
                or no role to play and the winner is entirely determined by the
                intellect and skill of the player
              </p>
              <p>
                The Central legislation governing gaming in India is the Public
                Gambling Act, 1867. Section 12 of the Public Gambling Act, 1867,
                provides that games of mere skill are exempt from the
                application of the Act. The Supreme Court of India and various
                High Courts in India have opined that a game of mere skill is a
                game “in which, although the element of chance necessarily
                cannot be entirely eliminated, success depends principally upon
                the superior knowledge, training, attention, experience and
                adroitness of the player. A game of skill is one in which the
                element of skill predominates over the element of chance.” No
                penalty can be imposed upon a person for playing such games of
                skill.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
