import React, { useState } from "react";
import { useQuery } from "react-query";
import "./Promotions.scss";
import "./PromotionDetails.scss";
import { promDetails, promotion } from "../components/Images";
import {
  getLeaderboardDetails,
  getPromotionDetails,
  getRaceDetails,
  getTournamentLeaderboardDetails,
} from "../queryFunctions";
import {
  NavLink,
  Outlet,
  useNavigate,
  useParams,
  Link,
} from "react-router-dom";
import PromotionsBanner from "./promotions/PromotionsBanner";
import LoadingElement from "../components/LoadingElement";

export default function PromotionDetails() {
  const [activeRace, setActiveRace] = useState("Promotion Details");
  const [showPromotionDetails, setShowPromotionDetails] = useState(false);
  const [showCategories, setShowCategories] = useState("race");
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const navigate = useNavigate();
  const [type, setType] = useState("");
  const { id, raceId, leaderId, tourId } = useParams();
  let promoid;
  let tourid;
  let leaderboardid;
  let raceid;
  let inc = 1;
  let str;
  console.log(id);
  const { data, isLoading, isError, error } = useQuery(["promotions", id], () =>
    getPromotionDetails(id)
  );
  console.log('detail',data);
  const tournamentLeaderboardAPI = useQuery(
    ["tournamentLeaderboards", promoid, tourid],
    () => getTournamentLeaderboardDetails(promoid, tourid),
    { enabled: false }
  );
  const leaderboardAPI = useQuery(
    ["leaderboards", promoid, leaderboardid],
    () => getLeaderboardDetails(promoid, leaderboardid),
    { enabled: false }
  );
  const raceAPI = useQuery(
    ["racedetails", promoid, raceid],
    () => getRaceDetails(promoid, raceid),
    { enabled: false }
  );
  const activeRaceHandler = (type, info, promoiden, otheriden) => {
    switch (type) {
      case "race":
        setType("race");
        console.log("promoiden");
        promoid = promoiden;
        raceid = otheriden;
        raceAPI.refetch();
        break;
      case "leaderboards":
        setType("leaderboards");
        promoid = promoiden;
        leaderboardid = otheriden;
        leaderboardAPI.refetch();
        break;
      case "tour":
        setType("tour");
        promoid = promoiden;
        tourid = otheriden;
        tournamentLeaderboardAPI.refetch(promoid, tourid);
      default:
        console.log("info");
    }
    console.log("info", info);
    console.log("leaderboardAPI", leaderboardAPI.data);
    setActiveRace(info);
  };
  const stringReplace = (value) => {
    let newString = value.split(" ").join("-");
    return newString;
  };
  if (isLoading) {
    return <LoadingElement />;
  }
  if (isError) {
    return <div>OOPS!! Something went wrong</div>;
  }
  return (
    <>
      <PromotionsBanner title={data.data.promo_detail.promotion_name} />
      {/* Promotion Details */}
      <div className=" row container-fluid ">
        <div
          className={`previous-promotions-container col-11 col-sm-10 col-md-9 col-lg-11  mx-auto  ${
            showPromotionDetails
              ? "selected-previous-promotions"
              : "previous-promotions"
          }`}
        >
          <div
            className={`d-flex justify-content-between align-items-center px-4 py-2 py-lg-3`}
            onClick={() => setShowPromotionDetails(!showPromotionDetails)}
          >
            <p className="previous-promotions-title">
              <span className="whitespace-nowrap">click here for</span>{" "}
              promotion details
            </p>
            <div className="previous-promotions-image" />
          </div>
          {showPromotionDetails && (
            <div className="d-none d-lg-flex flex-column mt-4 p-4 data-from-backend">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.data.promo_detail.promotion_desc1,
                }}
              />
            </div>
          )}
        </div>
        {showPromotionDetails && (
          <div className="d-flex col-11 col-sm-10 col-md-9 col-lg-11 flex-column d-lg-none mt-4 mx-auto data-from-backend">
            <div className="d-flex justify-content-between"></div>

            <div
              dangerouslySetInnerHTML={{
                __html: data.data.promo_detail.promotion_desc1,
              }}
            />
          </div>
        )}
      </div>
      {/* List of races */}
      <div className="promotion-details-stakes">
        {data.data.promo_detail.promotion_type === "Cash_Game" ? (
          data.data.stakes.length > 0 ? (
            data.data.stakes.map((item, index) => (
              <>
                {/* {(str = data.data.promo_detail.promotion_name)} */}
                {console.log(
                  "String Replace:",
                  stringReplace(data.data.promo_detail.promotion_name)
                )}
                {console.log("racetype_id", item.races[0]?.racetype_id)}

                <Link
                  // onClick={promotionDetail}
                  to={`/promotion/${id}/${stringReplace(
                    data.data.promo_detail.promotion_name
                  )}/${item.races[0]?.racetype_id}/${
                    item.races[0]?.leaderboard_id
                  }/${item?.stake_id}/${
                    item.races[0]?.leaderboard_id === undefined
                      ? "race"
                      : "race" //leaderboard
                  }`}
                  className="promotion-stakes-item"
                  key={index}
                >
                  <div className="title-text">{item.name}</div>
                  <div className="title-points ">{item.points}</div>
                </Link>
              </>
            ))
          ) : (
            <div>There are no stakes to display</div>
          )
        ) : null}
        {data.data.promo_detail.promotion_type === "Tournament" && (
          <table style={{ width: "100%" }} className="mx-5">
            <thead>
              <tr>
                <th>RANK</th>
                <th>NICKNAME</th>
                <th>POINTS</th>
              </tr>
            </thead>
            {data.tournament.leaderboard.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{Math.floor(item.points * 10000 * 10000) / 10000}</td>
              </tr>
            ))}
          </table>
        )}
        {data && data.data.promo_detail.promotion_type === "Cash_Tour" && (
          <table style={{ width: "100%" }} className="mx-5">
            <thead>
              <tr>
                <th>RANK</th>
                <th>NICKNAME</th>
                <th>POINTS</th>
                <th>VIP POINTS</th>
              </tr>
            </thead>
            {data.torunament_leaderboard && data.torunament_leaderboard.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item.player_name}</td>
                <td>{Math.floor(item.points * 10000 * 10000) / 10000}</td>
                <td>{item.vip_points}</td>
              </tr>
            ))}
          </table>
        )}
      </div>
      {/* Terms and conditions */}
      <div className=" row container-fluid ">
        <div
          className={`previous-promotions-container col-11 col-sm-10 col-md-9 col-lg-11  mx-auto  ${
            showTermsAndConditions
              ? "selected-previous-promotions"
              : "previous-promotions"
          }`}
        >
          <div
            className={`d-flex justify-content-between align-items-center px-4 py-2 py-lg-3`}
            onClick={() => setShowTermsAndConditions(!showTermsAndConditions)}
          >
            <p className="previous-promotions-title">
              <span className="whitespace-nowrap"> Terms of the</span> promotion
            </p>
            <div className="previous-promotions-image" />
          </div>
          {showTermsAndConditions && (
            <div className="d-none d-lg-flex flex-column mt-4 data-from-backend">
              <div className="px-4">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.data.promo_detail.promotion_tnc,
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {showTermsAndConditions && (
          <div className="d-flex col-11 col-sm-10 col-md-9 col-lg-11 flex-column d-lg-none mt-4 mx-auto data-from-backend">
            <div className="px-4">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.data.promo_detail.promotion_tnc,
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
