import React, { useState } from "react";
// import "./PromotionDetails.scss";
import "./PromotionRacesAndLeaderboards.scss";
import { promotionCarousel1 } from "../../components/Images";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import PromotionsBanner from "../promotions/PromotionsBanner";
import { arrowLeft, arrowRight } from "../../components/Images";

import Carousel, { consts } from "react-elastic-carousel";
import { useQuery } from "react-query";
import { getPromotionDetails } from "../../queryFunctions";
import { FaChevronLeft } from "react-icons/fa";

const arrowImages = ({ type, onClick, isEdge }) => {
  const img = type === consts.PREV ? arrowLeft : arrowRight;
  return (
    <img
      src={img}
      style={{ width: "9px", height: "14px", margin: "auto" }}
      onClick={onClick}
      disabled={isEdge}
    />
  );
};

export default function PromotionRacesAndLeaderboards() {
  const { id, raceId, leaderId,stakeId, tourId, name } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const replacedName = name.split("-").join(" ");
  let promoid;
  const [isActiveState, setActiveState] = useState("leaderboard");
  const breakPointsForRaces = [
    { width: 1, itemsToShow: 3 },
    { width: 568, itemsToShow: 5 },
    { width: 992, itemsToShow: 8 },
  ];
  console.log("location.data", location);
  const raceAPI = useQuery(
    ["promotiondetails", id],
    () => getPromotionDetails(id),
    {
      onSuccess: (data) => {
        console.log("race-details", data);
      },

      refetchInterval: 10000,
    }
  );
  console.log("getPromotionDetails",raceAPI);
  const isRace = location.pathname.split("/").includes("race");
  const isSocial = location.pathname.split("/").includes("social");
  const isLeaderboard = location.pathname.split("/").includes("leaderboard");
  if (raceId === "undefined" && leaderId === "undefined") {
    return (
      <>
        <PromotionsBanner title={replacedName} />
        <div className="container-fluid d-flex align-items-center justify-content-center mt-5">
          <p className="font-size">Races and leaderboard does not exist</p>
        </div>
        <div className="d-flex justify-content-center  mx-auto">
          <Link
            to={`/promotion/${id}`}
            className="title-text text-center  button-border-radius button-shadow px-4 py-3 py-lg-4 mt-5  unselected-button"
          >
            Go Back
          </Link>
        </div>
      </>
    );
  }
  return (
    <>
      <PromotionsBanner title={replacedName} />
      {/* Header selection between races and leaderboard for stakes */}
      <div className="promotion-races margin-top-after-banner row container-fluid">
        <div className="d-flex justify-content-center px-4 gap-5 col-11 col-sm-10 col-md-9 mx-auto position-relative">
          <div
            className="promotion-back-chevron-button"
            onClick={() => navigate(`/promotion/${id}`)}
          >
            <FaChevronLeft />
          </div>
          {stakeId !== "undefined" && raceAPI?.data?.data?.promo_detail?.social_leaderboard === 'yes' && (
            <Link
              to={`/promotion/${id}/${name}/${raceId}/${leaderId}/${stakeId}/social`}
              className={`title-text text-center  button-border-radius button-shadow px-4 py-3 py-lg-4 flex-basis-45 ${
                isSocial ? "selected-button" : "unselected-button"
              }
             `}
              // onClick={() => setActiveState("race")}
            >
              {/* {raceAPI.data.race_detail[0].racetype_name} */}
              Social Media LeaderBoard
            </Link>
          )}
          {leaderId !== "undefined" && (
            <Link
              to={`/promotion/${id}/${name}/${raceId}/${leaderId}/${stakeId}/leaderboard`}
              className={`title-text  text-center button-border-radius button-shadow px-4 py-3 py-lg-4 flex-basis-45 ${
                isLeaderboard ? "selected-button" : "unselected-button"
              } `}
              // onClick={() => setActiveState("leaderboard")}
            >
              <div className={``}>Leaderboard</div>
            </Link>
          )}
          {raceId !== "undefined" && (
            <Link
              to={`/promotion/${id}/${name}/${raceId}/${leaderId}/${stakeId}/race`}
              className={`title-text text-center  button-border-radius button-shadow px-4 py-3 py-lg-4 flex-basis-45 ${
                isRace ? "selected-button" : "unselected-button"
              }
             `}
              // onClick={() => setActiveState("race")}
            >
              {/* {raceAPI.data.race_detail[0].racetype_name} */}
              Race
            </Link>
          )}
        </div>
      </div>

      <Outlet />
      {/* Leaderboard Header Move it to a new function to display only in leaderboard file */}
    </>
  );
}
