import {
  depo,
  deposit,
  depositCardBg,
  depositMobile,
} from "../components/Images";
import "./Promotions.scss";
import "./PromotionDetails.scss";
import ReactTooltip from "react-tooltip";
import { useQuery } from "react-query";
import { getOffers } from "../queryFunctions";
import { useLocation, useParams } from "react-router-dom";
import { Fragment, useState } from "react";
import VisuallyHidden from "@reach/visually-hidden";
import { Dialog, DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import "./Deposits.scss";
import PromotionsBanner from "./promotions/PromotionsBanner";
import LoadingElement from "../components/LoadingElement";

export default function Deposit() {
  const location = useLocation();
  const isPathNameIncludesDeposit = location.pathname.includes("deposit");
  const isPathNameIncludesInstant = location.pathname.includes("instant");
  const [view, setView] = useState("none");
  const [offerData,setOfferData] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [tnc , setTnc] = useState("");
  const [details , setDetails] = useState("");
  const open = (index) => {
    setView(index);
    setShowDialog(true);
  };
  const close = (index) => {
    setView("none");
    setShowDialog(false);
  };

  const { data, isLoading, isError, error } = useQuery("offers", getOffers, {
    onSuccess: (data) => {
      console.log(`data for ${location.pathname}`, data);
      // const newData = data.data.filter((item) =>
      //   isPathNameIncludesDeposit
      //     ? item.offer_name === "Deposit Offer"
      //     : item.offer_name === "Deposit Bonus"
      // );
      // data.data = newData;
      setOfferData(data);
      console.log(`Processed data for ${location.pathname}`, data);
    },
  });
  if (isLoading) {
    return <LoadingElement />;
  }
  if (isError) {
    return <div>OOPS!! Something went wrong</div>;
  }
  return (
    <div>
      <PromotionsBanner
        title={isPathNameIncludesDeposit ? "Deposit & get Tourney Tickets" : isPathNameIncludesInstant ? "Instant Bonus" : "Deposit & Get Cash Back"}
      />

      <div className="deposit-content">
        <div className="container px-4"></div>
      </div>
      {/* Promotion Stakes in offers */}
      <div className="promotion-details-stakes">
        {offerData &&
        offerData.data.map((da,i) => (
          isPathNameIncludesDeposit ? (
          da.deposit_details?.map((item, index) => (
            da.offer_name === "Deposit Offer" ? (
            <Fragment key={index}>
              <div
                className="promotion-stakes-item position-relative"
                key={index}
                data-tip
                data-for="global"
                onClick={() => open(index)}
              >
                <div className="title-text"></div>
                <div className="title-points">{item.deposit}</div>
                <button className="clickbtn">Click here</button>
              </div>
              {view === index && (
                <Dialog
                  isOpen={showDialog}
                  onDismiss={close}
                  className="overlay"
                >
                  <button className="close-button" onClick={close}>
                    <VisuallyHidden>Close</VisuallyHidden>
                    <span aria-hidden>×</span>
                  </button>
                  <div className="deposit-cards">
                    <div className="row  ">
                      {item.deposit_hover.map((hoverItem, index) => (
                        <div className="col-lg-6 col-xl-4 my-4">
                          <div className="deposit-section-img mx-auto">
                            <img
                              alt=""
                              src={depositCardBg}
                              className="img-response"
                            />
                            <div className="centered-deposit-card  text-white">
                              Deposit
                              <br />
                              {hoverItem.amount}
                            </div>
                          </div>
                          <h3 className="text-center mt-2">{hoverItem.name}</h3>
                        </div>
                      ))}
                    </div>
                  </div>
                </Dialog>
              )}
            </Fragment>
            ) : ""
          ))
          ) : isPathNameIncludesInstant ? (
            da.deposit_details?.map((item, index) => (
              da.offer_name === "Instant Cashback Offers" ? (
              <>
                <div className="row  justify-content-center">
                  {item.deposit_hover.map((hoverItem, index) => (
                    <div className="col-lg-6 col-xl-4 my-4">
                      <div className="deposit-section-img mx-auto">
                        <img
                          alt=""
                          src={depositCardBg}
                          className="img-response"
                        />
                        <div className="centered-deposit-card  text-white">
                          Deposit
                          <br />
                          {hoverItem.amount}
                        </div>
                        <h3 className=" mt-2 mx-3">{hoverItem.name}</h3>
                      </div>
                    </div>
                  ))}
                </div>
              </>
              ) : ""
            ))
          ) : !isPathNameIncludesInstant || !isPathNameIncludesDeposit ? (
            da.deposit_details?.map((item, index) => (
              da.offer_name === "Deposit Bonus" ? (
              <Fragment key={index}>
                <div
                  className="promotion-stakes-item position-relative"
                  key={index}
                  data-tip
                  data-for="global"
                  onClick={() => open(index)}
                >
                  <div className="title-text"> {isPathNameIncludesDeposit ? "Amount Less Than" : "" }</div>
                  <div className="title-points">{item.deposit}</div>
                </div>
                {view === index && (
                  <Dialog
                    isOpen={showDialog}
                    onDismiss={close}
                    className="overlay"
                  >
                    <button className="close-button" onClick={close}>
                      <VisuallyHidden>Close</VisuallyHidden>
                      <span aria-hidden>×</span>
                    </button>
                    <div className="deposit-cards">
                      <div className="row  ">
                        {item.deposit_hover.map((hoverItem, index) => (
                          <div className="col-lg-6 col-xl-4 my-4">
                            <div className="deposit-section-img mx-auto">
                              <img
                                alt=""
                                src={depositCardBg}
                                className="img-response"
                              />
                              <div className="centered-deposit-card  text-white">
                                Deposit
                                <br />
                                {hoverItem.amount}
                              </div>
                            </div>
                            <h3 className="text-center mt-2">{hoverItem.name}</h3>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Dialog>
                )}
              </Fragment>
              ) : ""
            ))
          ) : ""
        ))}
      </div>

      <div className="deposit-contents">
        <div className="container px-5">
          <div className="main-head">
            <h2>Offer Details</h2>
          </div>
          <p>
          {offerData &&
          offerData.data.map((da,i) => (
            isPathNameIncludesDeposit ? (
            da.offer_name === "Deposit Offer" ? <div
            dangerouslySetInnerHTML={{
              __html: da.offer_detail,
            }}
          /> : "" ) : da.offer_name === "Deposit Bonus" ? <div
          dangerouslySetInnerHTML={{
            __html: da.offer_detail,
          }}
        />  : ""
            
            ))
          }
          {
            offerData &&
            offerData.data.map((da,i) => (
              isPathNameIncludesInstant ? (
                da.offer_name === "Instant Cashback Offers" ? <div
                dangerouslySetInnerHTML={{
                  __html: da.offer_detail,
                }} /> : ""
              ) : ""
            ))
          }
          </p>
        </div>
      </div>

      <div className="deposit-contents">
        <div className="container px-5">
          <div className="main-head">
            <h2>TERMS &amp; CONDITIONS</h2>
          </div>
          {offerData &&
          offerData.data.map((da,i) => (
            isPathNameIncludesDeposit ? (
            da.offer_name === "Deposit Offer" ? <div
            dangerouslySetInnerHTML={{
              __html: da.offer_desc,
            }}
          /> : "" ) : da.offer_name === "Deposit Bonus" ? <div
          dangerouslySetInnerHTML={{
            __html: da.offer_desc,
          }}
        />  : ""
            
            ))
          }
          {
            offerData &&
            offerData.data.map((da,i) => (
              isPathNameIncludesInstant ? (
                da.offer_name === "Instant Cashback Offers" ? <div
                dangerouslySetInnerHTML={{
                  __html: da.offer_desc,
                }} /> : ""
              ) : ""
            ))
          }
          <br />
        </div>
      </div>
    </div>
  );
}
