import React from "react";
import "./static.scss";

export default function ResponsibleGaming() {
  return (
    <div
      className="sidebar-page-container container"
      style={{ marginTop: "5%" }}
    >
      <div className="auto-container">
        <div className="row clearfix">
          <h5>
            <a href="index.aspx">Home</a> / Responsible Gaming
          </h5>
          {/* <!--Content Side--> */}
          <div className="content-side col-lg-12 col-md-12 col-sm-12 breadcrumb p-5 mt-4">
            <div className="services-single">
              <h4>Responsible Gaming</h4>
              <p>
                Pacific Gaming Private Limited (The Company) is dedicated to
                providing its members with a responsible gaming environment. Not
                only is it our mission to provide our members with the most
                entertaining gaming experience, but it is also our duty to help
                prevent compulsive usage of and underage access to our gaming
                products.
              </p>
              <p>
                As a responsible, regulated gaming company, we comply with all
                guidelines proposed by International regulators that are
                transparently monitored to provide player protection.
              </p>

              <p>The following policies have been implemented:</p>

              <h4>PROTECTION OF MINORS:</h4>
              <p>
                In line with international and national policies set forth by
                Gaming commissions, the company does not allow anyone under the
                age of 18 to play poker games. It is an offense for minors to
                participate on this website or its associated platforms.
              </p>
              <p>
                Our website or platforms are not designed to attract minors. We
                actively discourage and track persons under the age of 18 who
                attempt to play at our websites. We use sophisticated
                verification systems which can identify minors who log in to our
                software.
              </p>

              <p>
                Nevertheless, we recognize that the internet is readily
                accessible in many homes around the world. As a result, gaming
                companies and parents must work together to protect children
                from underage gaming. To ensure the safety of your children, we
                recommend implementing filtering software to block minors from
                accessing this website or any of its digital platforms.
              </p>

              <p>A few software’s that might help are:</p>
              <ul className="ter">
                <li> Cyber Patrol</li>
                <li> GamBlock / Gamban</li>
                <li> NetNanny</li>
              </ul>

              <p>Tips for parents:</p>
              <ul className="ter">
                <li>
                  {" "}
                  Do not leave children unattended near your system when this
                  website or its platforms are running. You must take special
                  care to ensure that they do no access our services via your
                  other devices.
                </li>
                <li>
                  {" "}
                  Do not share your usernames and passwords to the platform.
                </li>
                <li>
                  {" "}
                  Do not allow persons under the age of 18 to participate in
                  poker games.
                </li>
                <li>
                  {" "}
                  Educate your children about the legality and the potential
                  damage of underage gaming.
                </li>
              </ul>
              <p>
                Unfortunately, no system is foolproof. If you know a person
                under the age of 18 who is registered with us, please write to
                us on{" "}
                <a href="care@pokersaint.com">
                  <span
                    className="__cf_email__"
                    data-cfemail="305351425570405f5b55424351595e441e535f5d"
                  >
                    [email&#160;protected]
                  </span>{" "}
                </a>
                or call us on <a href="tel:080 6191 4949"> 080 6191 4949.</a>
              </p>

              <h4>PREVENTING COMPULSIVE GAMING</h4>
              <p>
                The company recognizes that while most of its users play for
                entertainment, a small number of people can become obsessed with
                the potential monetary gains our games offer. Even though
                studies suggest that only a very small percentage of adult
                population encounters compulsive gaming problems, we take this
                matter very seriously and have hence implemented a number of
                measures to address this problem:
              </p>
              <ul className="ter">
                <li>
                  The company’s staff are trained to recognize behavioral
                  patterns of users that may suggest compulsiveness to play our
                  games. They will immediately contact the user and initiate a
                  discussion with our analyst.
                </li>
                <li>
                  {" "}
                  We have implemented an easy way to use or “self-exclusion”
                  program for those who may need our assistance. Upon your
                  request, we will cancel your membership and will prevent you
                  from entering our platform.
                </li>
                <li>
                  {" "}
                  Our team can assist you in setting a maximum deposit limit.
                  Once this limit is reached, you will not be allowed to deposit
                  more and hence will not be able to play any games.
                </li>
              </ul>
              <p>
                Remember that gaming is a form of entertainment. It is not a way
                to get rich quickly and pay off any debts.
              </p>
              <p>Make sure that the decision to play poker is your choice.</p>
              <p>
                Check the amounts you spend on a regular basis in your cashier
                page.
              </p>
              <p>Make sure you know the rules of the game before you play.</p>

              <h4>ADVICE AND HELP:</h4>
              <p>
                You may seek professional help from various platforms like
                Gamblers Anonymous or contact us and we can connect you to the
                nearest professional help.{" "}
              </p>

              <h4>SET LIMITS</h4>
              <p>
                As part of our policy, we give you the tools to control the
                amount of money you use to pay on our sites. You can set your
                own deposit limits and request to adjust them at any time.
                Simply call our support team on 080 6191 4949 or email us on{" "}
                <a href="care@pokersaint.com">
                  <span
                    className="__cf_email__"
                    data-cfemail="7e1d1f0c1b3e0e11151b0c0d1f17100a501d1113"
                  >
                    [email&#160;protected]
                  </span>{" "}
                </a>{" "}
                in case you find difficulty in setting these limits.
              </p>
              <p>
                Note that the limits you set on this platform will be
                implemented only on this site or platforms related to this site
                and will not be implemented on other games provided by the
                company.
              </p>

              <h4>TAKE A BREAK, SELF EXCLUSIONS</h4>
              <p>
                If you wish to take a break and suspend your account for a
                particular duration, please call our support team on 080 6191
                4949 or email us on{" "}
                <a href="maitlto:care@pokersaint.com">
                  <span className="__cf_email__">[email&#160;protected]</span>{" "}
                </a>{" "}
                and someone from our team will assist you.
              </p>
              <p>
                Note that the suspension limits you set on this platform will be
                implemented only on this site or platforms related to this site
                and will not be implemented on other games provided by the
                company.
              </p>
              <p>
                Please note that your request to self-exclude may take up to 48
                hours.
              </p>
              <p>
                Note that during the self-exclusion period, you may still
                receive promotional information on your device or emails. In
                case you wish to be excluded from promotional content from us,
                please request for the same from our team and we will have that
                implemented.
              </p>

              {/* <h4>SUPPORT</h4>

              <a href="mailto:care@pokersaint.com">
                Email:{" "}
                <span className="__cf_email__">[email&#160;protected]</span>
              </a>
              <br />

              <a href="tel:080 6191 4949">Call: 080 6191 4949</a>
              <br />

              <a
                href="https://api.whatsapp.com/send?phone=918880071071&text=&source=&data="
                target="blank"
              >
                Whatsapp: 88800 71071
              </a>
              <br />

              <a
                href="https://www.facebook.com/Poker-saint-512868335724087/?view_public_for=512868335724087"
                target="blank"
              >
                {" "}
                Facebook: PokerSaint page
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
