import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  bonusOffers,
  depositOffers,
  depositOffersHover,
  bonusOffersHover,
  logo,
  s1,
  s2,
  s3,
} from "./Images";
import "./Header.scss";
export default function Header() {
  const [isOpen, setOpen] = useState(false);

  const [stickyClass, setStickyClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 150 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };
  const closeNav = () => {
    console.log("closed");
    setOpen(false);
  };
  const openNav = () => {
    console.log("open");
    setOpen(true);
  };
  const downloadButtonHandler = () => {
    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      //   window.location.href = 'https://files.pokersaint.com/downloads/PokerSaintAlpha/android/PokerSaint.apk';
      window.location.href =
        "https://files.pokersaint.com/downloads/PokerSaintAlpha/android/PokerSaint.apk";
    }
    if (navigator.userAgent.toLowerCase().indexOf("mac") > -1) {
      window.location.href =
        "https://files.pokersaint.com/downloads/mac/PokerSaintSetup.dmg";
    }
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      window.location.href =
        "https://apps.apple.com/in/app/poker-saint/id1294659165";
    }
    if (navigator.userAgent.toLowerCase().indexOf("iPod") > -1) {
      window.location.href =
        "https://apps.apple.com/in/app/poker-saint/id1294659165";
    }
    if (navigator.userAgent.toLowerCase().indexOf("iPad") > -1) {
      window.location.href =
        "https://apps.apple.com/in/app/poker-saint/id1294659165";
    }
    if (navigator.userAgent.toLowerCase().indexOf("webOS") > -1) {
      window.location.href =
        "https://apps.apple.com/in/app/poker-saint/id1294659165";
    }
    if (navigator.userAgent.toLowerCase().indexOf("Macintosh") > -1) {
      window.location.href =
        "https://apps.apple.com/in/app/poker-saint/id1294659165";
    }
    if (navigator.userAgent.toLowerCase().indexOf("windows") > -1) {
      window.location.href =
        "https://files.pokersaint.com/downloads/win/PokerSaintSetup.exe";
    }
    closeNav();
  };

  const redirector = (e) => {
    window.open("https://web.pokersaint.com");
    closeNav();
  };

  return (
    <div>
      {/* Uncomment the below code for implementing sticky code */}
      {/* <div className={stickyClass}> */}
      <div>
        <nav className={`container-fluid`}>
          <div className="header d-flex justify-content-between mx-auto">
            <div className="d-flex align-items-center">
              <Link className="navbar-brand" to="/" onClick={closeNav}>
                <img alt="" src={logo} className="main_logo img-fluid" />
              </Link>
            </div>
            <ul className="d-flex align-items-center mb-0">
              <li className="d-none d-lg-inline-block navbar-header-li header-offers">
                <div onClick={closeNav} className="link  uppercase">
                  Offers
                  <div className="header-dropdown">
                    <div className="dropdown-background">
                      <Link
                        to="/offers/deposit"
                        className="link uppercase"
                        onClick={closeNav}
                      >
                        Tourney Ticket <br/> Offers
                      </Link>
                      {/* <div
                        style={{
                          border: "#000000 1px solid",
                          marginRight: "20px",
                        }}
                      />
                      <Link
                        to="/offers/bonus"
                        className="link uppercase"
                        onClick={closeNav}
                      >
                        Cash back <br/> Offers
                      </Link> */}
                      <div
                        style={{
                          border: "#000000 1px solid",
                          marginRight: "20px",
                        }}
                      />
                      <Link
                        to="/offers/instant"
                        className="link uppercase"
                        onClick={closeNav}
                      >
                        Instant <br/> Bonus
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
              <li className="d-none d-lg-inline-block navbar-header-li z-20">
                <Link
                  to="/promotions"
                  onClick={closeNav}
                  className="link uppercase"
                >
                  Promotions
                </Link>
              </li>
              <li className="d-none d-lg-inline-block navbar-header-li my-auto button-margin-right">
                <button onClick={redirector} className="header--sidebar-button">
                  PLAY NOW
                </button>
              </li>
              <li className="d-none d-lg-inline-block navbar-header-li my-auto">
                <button
                  type="button"
                  className="header--sidebar-button"
                  onClick={downloadButtonHandler}
                >
                  DOWNLOAD APP
                </button>
              </li>
              <button className="openbtn my-auto d-lg-none" onClick={openNav}>
                ☰
              </button>
            </ul>
          </div>
          <div
            id="mySidebar"
            className="container sidebar d-lg-none"
            style={isOpen ? { width: "250px" } : { display: "none" }}
          >
            <div className="closebtn" onClick={closeNav}>
              ×
            </div>
            <Link to="/promotions" onClick={closeNav} className="uppercase">
              <img alt="" src={s1} />
              Promotions
            </Link>
            {/* <Link to="/offers" onClick={closeNav} className="uppercase">
              <img alt="" src={s2} />
              Offers
            </Link> */}
            <Link to="/offers/deposit" className="uppercase">
              {/* <img alt="" src={depositOffers} /> */}
              Tourney Ticket Offers
            </Link>
            <Link to="/offers/bonus" className="uppercase">
              {/* <img alt="" src={bonusOffers} /> */}
              Cash back Offers
            </Link>
            {/* <Link to="/achievements" onClick={closeNav}>
            <img alt="" src={s3} />
            Achivements
          </Link>
          <Link to="/tournaments" onClick={closeNav}>
            <img alt="" src={s3} />
            Tournaments
          </Link>
          <Link to="/faq" onClick={closeNav}>
            <img alt="" src={s2} />
            FAQ
          </Link>
          <Link to="/blogs" onClick={closeNav}>
            <img alt="" src={s2} />
            Blog
          </Link> */}
            <button
              className="d-block d-lg-none mx-auto my-3 uppercase width-80 header--sidebar-button"
              onClick={redirector}
            >
              PLAY NOW
            </button>
            <button
              className=" d-block d-lg-none mx-auto my-3 uppercase width-80 header--sidebar-button"
              onClick={downloadButtonHandler}
            >
              DOWNLOAD APP
            </button>
          </div>
        </nav>
      </div>
      {/* <nav className="navbar navbar-inverse mobile">
        <div className="container">
          <div className="navbar-header">
            <Link className="navbar-brand" to="/">
              <img alt="" src={logo} className="main_logo img-fluid" />
            </Link>
            <ul className="nav navbar-nav">
              <button type="button" className="btn btn-primary desktop">
                PLAY NOW
              </button>
              <button type="button" className="btn btn-primary desktop">
                DOWNLOAD APP
              </button>
              <button className="openbtn" onClick={openNav}>
                ☰
              </button>
            </ul>
          </div>
        </div>
      </nav> */}
    </div>
  );
}
