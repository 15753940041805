import { arrowLeft, arrowRight } from "./Images";

import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useState } from "react";
import Carousel, { consts } from "react-elastic-carousel";

const TestimonialCard = ({ image, text, name }) => {
  return (
    <div className="h-100">
      <div className="d-flex flex-column  m-3 testimonial-width justify-content-center testimonial-height">
        <div
          className="testimonial-image-container mx-auto"
          // style={{ margin: "auto" }}
          style={{ zIndex: "20" }}
        >
          <img
            src={image}
            // srcSet={`${testimonialImage1x} 300w,${testimonialImage2x} 768w,${testimonialImage3x} 992w`}

            className="mx-auto testimonial-image-margin testimonial-image"
          />
        </div>
        <div className="testimonial-card">
          <h3 className="text-center mt-5">{name}</h3>
          <p className="text-center">{text}</p>
        </div>
      </div>
    </div>
  );
};
const arrowImages = ({ type, onClick, isEdge }) => {
  const img = type === consts.PREV ? arrowLeft : arrowRight;
  return (
    <img
      src={img}
      style={{ width: "9px", height: "14px", margin: "auto" }}
      onClick={onClick}
      disabled={isEdge}
    />
  );
};

export default function Testimonials({ data }) {
  const [current, setCurrent] = useState(0);
  const length = data.length;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 568, itemsToShow: 2 },
    { width: 992, itemsToShow: 3 },
  ];

  return (
    <div className="container px-5">
      {/* <Slider {...settings}>
        {data.data.map((item) => (
          <TestimonialCard
            name={item.testimonial_name}
            key={item.testimonial_id}
            image={item.testimonial_image}
            text={item.testimonial_text}
          />
        ))} */}

      {/*
       <div className="d-flex flex-row justify-content-between  hide-scrollbar align-items-center">
        <div>
          <img src={arrowLeft} />
        </div>
        <div className="d-flex overflow-auto hide-scrollbar row flex-nowrap mx-2">
          <TestimonialCard />
          <TestimonialCard />
          <TestimonialCard />
          <TestimonialCard />
        </div>
        <div>
          <img src={arrowRight} />
        </div>
      </div> */}

      <Carousel
        renderArrow={arrowImages}
        breakPoints={breakPoints}
        pagination={false}
        transitionMs={500}
      >
        {data.data.map((item) => (
          <TestimonialCard
            name={item.testimonial_name}
            key={item.testimonial_id}
            image={item.testimonial_image}
            text={item.testimonial_text}
          />
        ))}
      </Carousel>
      {/* </Slider> */}
    </div>
  );
}
