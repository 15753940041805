import React from "react";
import { Link } from "react-router-dom";
import "./static.scss";
export default function TermsOfUse() {
  return (
    <section style={{ marginTop: "5%" }} className="container">
      <div className="sidebar-page-container">
        <div className="auto-container">
          <div className="row clearfix">
            <h5>
              <a href="/">Home</a> / Terms and Condition
            </h5>
            {/* <!--Content Side--> */}
            <div className="content-side col-lg-12 col-md-12 col-sm-12 breadcrumb p-5 mt-4">
              <div className="services-single">
                <h1>Terms and conditions </h1>
                <div className="text tnc">
                  <p>
                  These Terms and Conditions (hereinafter referred to as Agreement) govern the legal relationship between Pacific Gaming Pvt. Ltd., India (hereinafter referred to as Poker Saint OR Company) and the end users / players{" "}
                  </p>
                  <p>
                    These Terms, contain provisions that define your limits, legal rights, and obligations with respect to your use of and participation in the Poker Saint website and/or mobile application, including any advertisements, forums, various email functions and Internet links, and all content and Poker Saint services available through the domain and sub-domains of Poker Saint located at {" "}
                    <Link to="/">www.pokersaint.com</Link>(hereinafter collectively referred to as “Website”)
                  </p>

                  <p>
                  All references to “you” or “your”, as applicable, shall mean the person that accesses, uses, and/or participates in the Website in any manner. If you become a Registered Member (as defined below) on behalf of any legal person, you represent and warrant that you have the authority to bind such legal person and your acceptance of these Terms will be deemed as acceptance by that legal person and “you” and “your” herein shall refer to that legal person.
                  </p>

                  <h1>Description of services offered</h1>
                  <p>
                  <Link to="/">www.pokersaint.com</Link> is an online skill-based website, providing top of the line software’s and an impeccable customer experience for poker & other skill-based card game enthusiasts in India. We aim to bring to India the same levels of gameplay and network performance in online games as customers have access to internationally and build a robust platform for online skill-game enthusiasts in the country. We provide IT and technology services to promote online games of skill, specifically online poker, for real money as well as play money games (“Services”). The Services provided by this website intended solely to encourage gameplay on the website, and for entertainment purposes. This website is not responsible for any acts that may take place outside the purview of the website.
                  </p>
                  <h1>Refund/ Exchange Policy:</h1>
                  <p>
                    Due to the nature of the business, <Link to="/">www.pokersaint.com</Link> does not provide refunds or Chargebacks or exchanges after the Service has been provided by the Company.
                  </p>
                  <p>
                    Users must use any money in their Account within 360 days. Pacific Gaming will have the right to directly forfeit any such unused amount after 361 (three hundred and sixty one) days. The idle Balance amount lying in your account may be withdrawn only in exceptional circumstances, determined on a case to case basis on the sole discretion of the company.
                  </p>

                  <h1>Changes to the Services</h1>
                  <p>
                    We may from time to time add new services to the Services, substitute a new service for one of the existing Services, or discontinue or suspend one of the existing Services. Information about the new services will be included on the Website, and the users of new services will be governed by these Terms. You agree that Poker Saint will not be liable to you or any third party for any suspension or discontinuation of any of the Services.
                  </p>

                  <h1>Use of Website</h1>
                  <p>
                    By accessing and using the Website and the functionalities and Services provided by the company, you hereby agree and confirm that you have clearly and unambiguously read and understood the Terms and Conditions, Privacy Policy, Disclaimer, and all other documents attached with this Website. If you have not read these, please read the same carefully and only then use the Website. If you do not accept these Terms, you must discontinue the use of the company’s websites or applications. By accepting these Terms, you are entering into a legally binding Agreement with Poker Saint.
                  </p>
                  <p>
                    All the Documents provided by this Website and all other Terms and Conditions as separately provided by any pamphlet, promotional offer, advertisement between the Website and the Users, are part and parcel of this Website and the User has agreed to all of them in a composite and comprehensive manner to access and use this Website, its functionalities and Services.
                  </p>

                  <h1>Changes to These Terms</h1>
                  <p>
                    We may change these Terms at any time, as we reasonably deem appropriate. Upon any change in these Terms, we will post the amended agreement on the Website with notice of the changes. Your continued use of the Website and/or the Services following such notification shall constitute your affirmative acknowledgement of the Terms, the modification and agreement to abide and be bound by the Terms, as amended. If at any time you choose not to accept these Terms, including following receipt of notification of any modifications hereto, do not use the Website, or the Services.
                  </p>

                  <h1>License and Access</h1>
                  <p>
                    Poker Saint hereby grants you a limited, non-exclusive, revocable license to use the Website as set forth in these Terms; provided, however, that{" "}
                  </p>
                  <p>
                    you will not copy, distribute, or make derivative works of the Website in any medium without the company’s prior written consent;
                  </p>
                  <p>
                    you will not alter or modify any part of the Website other than as may be reasonably necessary to use the Website for its intended purposes; and
                  </p>
                  <p>
                    you will otherwise act in accordance with the terms and conditions of the Terms and in accordance with all applicable laws.
                  </p>

                  <h1>Restricted Locations</h1>
                  <p>
                      The online games and the services available on the Platform are open only to Persons currently residing in India, with certain restrictions. Persons residing in the state of Assam, Odisha, Nagaland, Andra Pradesh, Gujrat and Telangana (“Restricted States”) are prohibited from participating in any online games on the Platform. The company shall not be liable for any user who willingly or unwillingly participates in games that are not permitted from the mentioned Restricted states.
                  </p>
                  <h1>Use of Deposited amounts:</h1>
                  <p>
                    The user accepts that they will use a minimum of 80% of the deposited amount to play the online games offered on the website. In accordance with the Refund Policy mentioned here, users cannot deposit and withdraw money without playing on the offered games. If a user fails to meet these criteria, a platform handling fee of 10% will be charged on the deposit amount made.
                  </p>
                  <p>
                    If you do not wish to commit 80% of the deposit amount before withdrawing your funds, please deposit smaller amounts or refrain from using this platform.
                  </p>
                  <p>Winnings if any, are liable to taxes as per the rules and regulations of the government of India.</p>

                  <h1>User Validation / KYC</h1>
                  <p>
                    Every user on the website is subject to ‘User Validation’ process. First time user validation process takes between 24-48 hours via the "KYC Validation" process in the application. A user is expected to provide PAN copy, Address proof copy, banking details to ensure that he/she is over the age of 18 years, is not playing from a restricted state and to verify banking information. This platform cannot be used to deposit and withdraw money without playing on the games. For any issue regarding KYC, please email kyc@pokersaint.com or contact the support team at care@pokersaint.com.
                  </p>

                  <h1>Game winnings & Taxes</h1>
                  <ul>
                    <li>Awards for all Contests shall be pre-declared at the time of registration. Awards may be in the form of Tokens, cash or promotional gifts.</li>
                    <li>Users are eligible to withdraw only cash Winnings from his/her Account to his/her respective bank/wallet account once the account is KYC (Know Your Customer) verified</li>
                    <li>
                     The Company does not issue receipts for entries in the Contests. The entries are accounted within the Platform. It is the responsibility of the Users to ensure that the entries are accurate, and the Winnings are duly credited to his Account.
                    </li>
                    <li>
                      As per the Section 194BA of the Income Tax Act, 1961, 30% TDS (Tax deducted at source) will be deducted on any online game winnings. This amount shall be paid by the company against the PAN card submitted by the user at the time of KYC validation. It is the user's responsibility to ensure that valid PAN card is submitted to the company.
                    </li>
                  </ul>

                  <h1>Commissions & Taxes</h1>
                  <ul>
                    <li>The company issues commissions to individuals or organizations who promote the website and its associated applications</li>
                    <li>Commissions can be earned by users of the website also who promote the website.</li>
                    <li>Commissions earned are liable to TDS at 5% under section 194H of the Income Tax Act.</li>
                    <li>Commissions will be paid as tokens on the platform.</li>
                  </ul>

                  <h1>Use of External Devices, HUD’s, VPN etc</h1>
                  <p>
                    The company prohibits the use of External Player Assistance Programs, Virtual Private Networks or Heads Up Displays which are designed to provide an unfair advantage to any Users. The User acknowledges that any information on other players beyond that which the User has personally observed through the User's own game play constitutes an unfair advantage. You agree that The Company may take steps to detect and prevent the use of these software’s, hardware’s or any other external devices or applications. These steps may include, but are not limited to, examination of software programs running concurrently with the Software on the User's computer, examination of use of VPN devices, Player assistance programs, Geo position data or any other action as required by the company to maintain fair play on the website.
                  </p>

                  <h1>Use of automatic player software’s</h1>
                  <p>
                    The use of artificial intelligence including, without limitation of "robots" or "bots" or any other forms of external digital assistance devices by Users is strictly forbidden in connection with the Service. The company will take all actions necessary to prevent or stop such activities including but not limited to, permanent stoppage of access to the website, freezing of funds or any other action that the company may deem adequate. All actions taken in relation to the Service by a User must be executed personally by the user through the user interface accessible provided by the company in the manner intended by the company only.
                  </p>

                  <h1>Chip Dumping Activities</h1>
                  <p>
                    Chip-dumping occurs when any User intentionally loses a hand to deliberately transfer his chips to another User while having a chance to win. Any User who chip-dumps or attempts to chip-dump with any other User, or who benefits from such actions taken by another User while using the Service may be permanently banned from using the Service and their account may be terminated immediately, without release of funds. In such circumstances the company will be under no obligation to refund any monies that may be in such Users’ accounts at such time and the Website will have the right to initiate legal proceedings against the offending player.
                  </p>
                  <h1>Fraudulent Behaviour</h1>
                  <p>
                    In the event that the Company deems that a User has engaged or attempted to engage in fraudulent, unlawful, dishonest or improper activity while using the Service, or any other game manipulation, or the making of any fraudulent payment, including without limitation, use of a stolen credit card or fraudulent chargeback. The company shall be entitled to take such action as it sees fit, including, but not limited to:
                  </p>
                  <p>Immediately blocking a User's access to the Service;</p>
                  <p>Terminating a User's account with the website;</p>
                  <p>Seizing the funds within a User's account;</p>
                  <p>Disclosing such information (including the identity of the User) to financial institutions, relevant authorities and/or any person or entity that has the legal right to such information; and/or</p>
                  <p>Taking legal action against a User.</p>

                  <h1>Multiple player accounts</h1>

                  <p>
                    The Company performs regular checks on accounts to maintain fairness and transparency on its platforms. When the Company becomes aware of multiple accounts created by a User, the company has complete authority and is entitled to take actions that are deemed fit and fare to players on the platform as listed below but not limited to:
                  </p>

                  <p>Termination of all the accounts with immediate effect.</p>
                  <p>Monetary penalty enforced at 30% of the account balance by deducting the chips from the accounts.</p>
                  <p>Forfeiting of all funds in the users’ accounts.</p>
                  <p>Taking any legal action against the User involved in the illegal activity.</p>

                  <h1>Sharing of personal Information including passwords</h1>

                  <p>
                    Passwords are unique identifiers of any online account. Upon registering on the website, you agree that you will not share device passwords, account passwords or any other sensitive information to any other individual. The Company will not be responsible if your account is accessed, or other actions are performed by other individuals using your password. You understand and agree that the Company will not incur any liability for information provided by you to anyone which may result in your account on the platform being exposed or misused by any other person and any loss thus incurred.
                  </p>

                  <h1>Collusion policy</h1>

                  <p>
                    The act of sharing game related information during the game is called Collusion. Collusion between Players by sharing hole cards or by any other methods is strictly prohibited. Players found intentionally soft playing against his/her opponent or knowing other Player's card before the hand goes to showdown or conspiring with each other with the intention to intentionally cause financial or material loss to other players on the platform is considered as collusion
                  </p>

                  <p>
                    When the Player agrees to the Terms and Conditions, we consider any collusion between Players as a breach of these Terms. The Company shall have the right to take appropriate action against the Players, as it deems fit. Penalties may include, but not limited to:
                  </p>

                  <p>
                    Penalty of funds/amount/chips/bonus deduction or freezing of all funds from the account of all Players involved in illegal activity of Collusion.
                  </p>

                  <p>
                    Blocking of access of all the Players involved in illegal activity of Collusion, not limited to ejecting them from all cash tables and not allowing them to continue in any running tournament that they are playing and/or are registered in any tournaments.
                  </p>

                  <p>
                  Termination/closure of PokerSaint Account of all the Players involved in illegal activity of Collusion.
                  </p>

                  <p>Taking appropriate legal action against all the Players for causing losses to other players with a criminal intent to make unlawful gains. </p>

                  <h1>Data retention policy</h1>

                  <p>While there is no defined duration for the retention of user information provided by the Ministry of Electronics and Information Technology, the company shall retain user information of all users, even user data where users want to delete the application or website.</p>

                  <h1>Contact information</h1>

                  <p>Email: care@pokersaint.com</p>

                  <p>Chat support: Icon available on website and mobile applications.</p>

                  <h1>Nodal officer:</h1>
                  <p>rachith@thepacificgaming.com</p>
                  <p>archit@thepacificgaming.com</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
