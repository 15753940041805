import { achievements } from "../components/Images";
export default function Achievements() {
  return (
    <>
      <div className="d-none d-lg-block container-fluid px-5">
        <div id="myCarousel" className="carousel slide" data-ride="carousel">
          {/* <!-- Wrapper for slides --> */}
          <div className="carousel-inner">
            <div className="item active" style={{ borderRadius: "15px" }}>
              <img
                src={achievements}
                alt="Los Angeles"
                style={{ width: "100%", borderRadius: "15px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container deposit-content promotion-content">
        <div className="">
          <div
            className="main-head d-none d-lg-block"
            style={{ borderBottom: "1px solid" }}
          >
            <h2>Achievements</h2>
          </div>
          <div className="main-head d-lg-none">
            <h2>Achievements</h2>
          </div>
        </div>
        <div className="d-lg-none">
          <img src={achievements} alt="Achivements" style={{ width: "100%" }} />
        </div>

        <div className="row  justify-content-center">
          <div className="col-sm-6 col-lg-3 bbtn row">
            <div>
              <button
                className="btn btn-primary btns "
                style={{ background: "#00a99d", border: "none" }}
              >
                Battlefield Omaha
              </button>
            </div>
            <p>NICK NAME AND PRIZE WON</p>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 1</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 2</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 3</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 4</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 5</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 6</p>
              <p>PRIZE WON</p>
            </div>
          </div>

          <div className="col-sm-6 col-lg-3 bbtn row">
            <div>
              <button
                className="btn btn-primary btns"
                style={{ background: "#0071bc", border: "none" }}
              >
                The Poker Paradox
              </button>
            </div>
            <p>NICK NAME AND PRIZE WON</p>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 1</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 2</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 3</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 4</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 5</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 6</p>
              <p>PRIZE WON</p>
            </div>
          </div>

          <div className="col-sm-6 col-lg-3 bbtn row">
            <div>
              <button
                className="btn btn-primary btns"
                style={{ background: "#fbb03b", border: "none" }}
              >
                The Enigma Series
              </button>
            </div>
            <p>NICK NAME AND PRIZE WON</p>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 1</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 2</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 3</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 4</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 5</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 6</p>
              <p>PRIZE WON</p>
            </div>
          </div>

          <div className="col-sm-6 col-lg-3 bbtn row">
            <div>
              <button
                className="btn btn-primary btns"
                style={{ background: " #662d91", border: "none" }}
              >
                Return of the Kings
              </button>
            </div>
            <p>NICK NAME AND PRIZE WON</p>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 1</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 2</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 3</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 4</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 5</p>
              <p>PRIZE WON</p>
            </div>
            <div className="ach col-6 col-lg-12">
              <p className="btn-border">Name 6</p>
              <p>PRIZE WON</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
