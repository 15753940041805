import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./App.scss";
import "./style.scss";
import "./responsive.scss";
import { QueryClientProvider, QueryClient } from "react-query";
import RoutesFunction from "./routes/index";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  const queryClient = new QueryClient();

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Header />
        <RoutesFunction />
        <Footer />
      </QueryClientProvider>
    </div>
  );
}

export default App;
